import "./RecatangleTextBtn.scss";

const RecatangleTextBtn = ({
  text = "Button", //버튼에 들어갈 글자
  sizeClass = "", //사이즈를 위한 css     :: normal
  colorClass = "", //색상 지정을 위한 css  :: black, gray, line
  action = true, //버튼 활성화 css
  ...props
}) => {
  return (
    <button
      className={
        action
          ? `h4_regular rectangleTextBtn ${sizeClass} ${colorClass}`
          : `h4_regular rectangleTextBtn no ${sizeClass} ${colorClass}`
      }
      onClick={action ? props.onClick : () => null}
    >
      {props?.icon && <i className={`svg`}>{props?.icon}</i>}
      {text}
      {props.children}
    </button>
  );
};

export default RecatangleTextBtn;
