import "./SortBtn.scss";

import { ReactComponent as ArrowDownIcon } from "assets/svg/ic_arrow_down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/svg/ic_arrow_up.svg";

const SortBtn = ({ ...props }) => {
  const { text, sort, click, type } = props;

  const onClick = () => {
    let count = sort;
    count = count + 1;
    if (count === 3) {
      count = 1;
    }
    click(type, count);
  };

  return (
    <button
      className={
        sort === 0
          ? `caption3_regular sortBtn sort0`
          : `caption3_regular sortBtn`
      }
      onClick={onClick}
    >
      {text}
      {sort === 1 && <ArrowDownIcon />}
      {sort === 2 && <ArrowUpIcon />}
    </button>
  );
};

export default SortBtn;
