import { useState } from "react";
import * as S from "./EndBtns.Styled";
import { ReactComponent as IconSystemShut2Lined } from "assets/svg/ic_system_shut_2_lined.svg";
import { useTranslation } from "react-i18next";
import ProgramEndModal from "components/modal/programEndModal/ProgramEndModal";

const EndBtn = () => {
  const { t } = useTranslation();
  const [onoff, setOnoff] = useState(false);

  return (
    <>
      <S.EndBtn onClick={() => setOnoff(true)}>
        <IconSystemShut2Lined />
        {t("Kiosk_Login_Exit_BTN")}
      </S.EndBtn>
      {onoff ? (
        <p className={`dimmed`} onClick={() => setOnoff(false)}></p>
      ) : (
        <></>
      )}
      <ProgramEndModal onoff={onoff} onCancel={() => setOnoff(false)} />
    </>
  );
};

export default EndBtn;
