import { useTranslation } from "react-i18next";
import { cAPIMessagePack } from "utils/cAPI";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "modules";
import "./FirstStudio.scss";
import { ReactComponent as Logo } from "assets/logo/logo_metagym_symbol.svg";
import { ReactComponent as OpenInWindowLinedIcon } from "assets/svg/ic_open_in_window_lined.svg";
import RecatangleTextBtn from "components/butttons/recatangleTextBtn/RecatangleTextBtn";

const FirstStudio = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const store = useSelector((state: RootState) => state);
  const usridx = store.local.usridx;

  return (
    <div className={`firstStudio`}>
      <div className={`firstStudioContainer`}>
        <p className={`logo`}>
          <Logo />
        </p>
        <p className={`d1_medium title`}>MetaGym Exercise Studio</p>
        <p className={`h2_regular desc`}>
          나만의 운동 프로그램을 제작하고 이용해보세요!
          <br />
          트레이너는 운동 프로그램을 사용자에게 제공하여 수익을 낼 수 있습니다!
        </p>
        <p className={`btn`}>
          {/* 신규 프로그램 제작 */}
          <RecatangleTextBtn
            text={t("ExerciseStudioPage_Btn1")}
            colorClass="line"
            onClick={() => {
              usridx > 0
                ? cAPIMessagePack(`exbodyKiosk:?type=3&usridx=${usridx}`)
                : navigate("/login");
            }}
          >
            <OpenInWindowLinedIcon />
          </RecatangleTextBtn>
        </p>
      </div>
    </div>
  );
};

export default FirstStudio;
