import { useTranslation } from "react-i18next";
import "./NoData.scss";
import { ReactComponent as WarningCilcelIconLine } from "assets/svg/ic_warning_circle_lined.svg";

const NoData = ({ text = "Exercise_SearchPage_SearchResultIsEmpty_Desc" }) => {
  const { t } = useTranslation();
  return (
    <div className={`noData`}>
      <p className={`img`}>
        <WarningCilcelIconLine />
      </p>
      <p className={`h4_regular text`}>{t(text)}</p>
    </div>
  );
};

export default NoData;
