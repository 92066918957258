import { store } from "../modules";
import { set_is_unity } from "../modules/local";

export const cAPIMessagePack = (strMsg) => {
  console.log("WEB cAPIMessagePack strMsg ::", strMsg);
  console.log("WEB  window.cAPI ::", window.cAPI);

  if (strMsg && window.cAPI) {
    store.dispatch(set_is_unity(true));
    window.cAPI?.messagePack(strMsg);
  }
};
