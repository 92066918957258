import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//styles
import "./RectangleSelect.scss";

//svg
import { ReactComponent as ChevronDownIcon } from "assets/svg/ic_chevron_down.svg";
import { ReactComponent as ChevroUpIcon } from "assets/svg/ic_chevron_up.svg";
import { ReactComponent as ChevroCheckIcon } from "assets/svg/ic_chevron_check.svg";
import { ReactComponent as WarningCilcelIconLine } from "assets/svg/ic_warning_circle_lined.svg";

const RectangleSelect = ({
  placeholder = "Kiosk_Common_Select_Text",
  errMessage = "",
  err = false,
  ...props
}) => {
  const { select } = props;
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [valueState, setValueState]: any = useState(select);

  useEffect(() => {
    setValueState(select);
  }, [select]);

  return (
    <div className={isOpen ? `rectangleSelect open` : `rectangleSelect`}>
      <p
        className={err && !isOpen ? `inputArea err` : `inputArea`}
        onClick={() => !props.readOnly && setOpen(!isOpen)}
      >
        <input
          className={`body_regular`}
          type="text"
          placeholder={t(placeholder)}
          value={valueState?.text && t(valueState?.text)}
          readOnly
        />
        <i>
          {isOpen ? (
            <ChevroUpIcon width="24px" height="24px" fill="#505050" />
          ) : (
            <ChevronDownIcon width="24px" height="24px" fill="#505050" />
          )}
        </i>
      </p>
      {isOpen && <p className={`closeArea`} onClick={() => setOpen(false)}></p>}
      {isOpen && (
        <div className={`menus`}>
          <ul className={`menuArea scroll`}>
            {props.menus?.map((menu: SelectType) => (
              <li
                className={`menu`}
                key={menu.key}
                onClick={
                  props.cate
                    ? () => {
                        props.onClick(props.cate, menu);
                        setOpen(false);
                      }
                    : () => {
                        props.onClick(menu);
                        setOpen(false);
                      }
                }
              >
                <p>
                  {t(menu.text)}
                  {select?.key === menu?.key && (
                    <ChevroCheckIcon
                      width="24px"
                      height="24px"
                      fill="#65aaff"
                    />
                  )}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )}
      {err && errMessage?.length > 0 && (
        <p className={`caption3_light error`}>
          <WarningCilcelIconLine width="16px" height="16px" fill="#ff3737" />
          {errMessage}
        </p>
      )}
    </div>
  );
};

export default RectangleSelect;
