import { useEffect, useState } from "react";
import "./ExerciseType.scss";
import { exerciseTools2, bodyCommonCode } from "datas/Exercise";

interface Props {
  commonCode: any[] | undefined;
  data?: ExTypeRectangleType;
  key?: string;
}

const ExerciseType = ({ commonCode, data, key }: Props) => {
  const [isToolBody, setToolBody]: any = useState(null);

  useEffect(() => {
    let mainTag = data?.exerciseMainTagId.sort(function (a: any, b: any) {
      return b < 20 && a < 20 ? a : b - a;
    });

    let newArr: any = [];

    if (commonCode && data && mainTag) {
      for (const tag of mainTag) {
        for (const element of commonCode) {
          if (element[0] === "exerciseMainTagId") {
            if (element[3] === 2 && tag.toString() === element[1]) {
              const newData = { icon: null, type: element[3] };
              bodyCommonCode.map((body: any) => {
                if (element[4] === body.text) {
                  newData.icon = body.icon;
                }
              });
              newArr.push(newData);
            } else if (element[3] === 3 && tag.toString() === element[1]) {
              const newData = { icon: null, type: element[3] };
              exerciseTools2.map((body: any) => {
                if (element[4] === body.text) {
                  newData.icon = body.icon;
                }
              });
              newArr.push(newData);
            }
          }
        }
      }
      setToolBody(newArr);
    }
  }, [commonCode, data]);

  return (
    <div className={`exerciseType`}>
      {isToolBody?.length > 0 ? (
        <>
          {isToolBody?.length > 0 &&
            isToolBody?.map((ToolBody: any, index: number) => (
              <>
                {index < 5 && (
                  <p
                    className={
                      ToolBody.type === 2
                        ? `bodyIcon typeIcon`
                        : `toolIcon typeIcon`
                    }
                    key={`toolIcon_${key}${index}`}
                  >
                    {ToolBody.type === 3 ? (
                      <span className={`icon`}>{ToolBody.icon}</span>
                    ) : (
                      <span className={`icon`}>
                        <img src={ToolBody.icon} alt="icon" />
                      </span>
                    )}
                  </p>
                )}
              </>
            ))}
          {isToolBody?.length > 5 && (
            <p className={`numIcon typeIcon`}>
              <span className={`icon caption3_regular`}>
                +{isToolBody?.length - 5}
              </span>
            </p>
          )}
        </>
      ) : (
        <>
          <p className={`no_data typeIcon`}>
            <span className={`no_data`}></span>
          </p>
          <p className={`no_data  typeIcon`}>
            <span className={`no_data`}></span>
          </p>
          <p className={`no_data typeIcon`}>
            <span className={`no_data`}></span>
          </p>
          <p className={`no_data typeIcon`}>
            <span className={`no_data`}></span>
          </p>
          <p className={`no_data typeIcon`}>
            <span className={`no_data`}></span>
          </p>
          <p className={`no_data typeIcon`}>
            <span className={`no_data`}></span>
          </p>
        </>
      )}
    </div>
  );
};

export default ExerciseType;
