import { useState } from "react";

import "./TypeTooltip.scss";
import Tooltip from "components/commons/tooltip/Tooltip";

const TypeTooltip = ({ text = "", ...props }) => {
  const [isHover, setHover] = useState(false);

  return (
    <div className={`typeTooltip`}>
      <div
        className={`tooltipBox`}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {/* <span className={`caption3_regular tooltip`}>{text}</span> */}
        <Tooltip text={text} hover={isHover} />
        {props.children && <div className={`typeIcon`}>{props.children}</div>}
        {props.icon && <p className={`typeIcon`}>{props.icon}</p>}
        {props.img && (
          <p className={`typeIcon`}>
            <img src={props.img} alt="icon" />
          </p>
        )}
        {!props.img && !props.icon && !props.children && (
          <p className={`typeIcon no_data2`}></p>
        )}
      </div>
    </div>
  );
};

export default TypeTooltip;
