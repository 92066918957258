import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "modules";
import parser from "html-react-parser";
import "./AlertModal2.scss";
import RecatangleTextBtn from "components/butttons/recatangleTextBtn/RecatangleTextBtn";

import { set_alert2_modal } from "modules/common";

const AlertModal2 = ({ ...props }) => {
  const store = useSelector((state: RootState) => state);
  const commonStroe = store.common;
  const alertModalData = commonStroe.alertModal2;
  const dispath = useDispatch();

  const body = document.querySelector("body") as HTMLElement;
  const [scrollPositions, setScrollPositions] = useState(window.scrollY);

  const leftFunc = () => {
    alertModalData.noFunc && alertModalData.noFunc();
    closeFunc();
  };

  const rightFunc = () => {
    closeFunc();
    alertModalData.okFunc && alertModalData.okFunc();
  };

  const closeFunc = () => {
    dispath(
      set_alert2_modal({
        onoff: false,
        title: null,
        text: null,
      })
    );
  };

  // 모달 위치 설정
  window.addEventListener("scroll", () => {
    if (window.scrollY !== 0) {
      setScrollPositions(window.scrollY);
    }
  });

  useEffect(() => {
    if (alertModalData.onoff) {
      body.style.top = `-${scrollPositions}px`;
      body.style.overflow = "hidden";
      body.style.position = "fixed";
      body.style.left = "0";
      body.style.right = "0";
    } else {
      body.style.removeProperty("overflow");
      body.style.removeProperty("position");
      body.style.removeProperty("top");
      body.style.removeProperty("left");
      body.style.removeProperty("right");
      window.scrollTo(0, scrollPositions);
    }

    return () => {};
  }, [alertModalData.onoff]);

  return (
    <>
      {alertModalData.onoff ? (
        <p className={`dimmed`} onClick={closeFunc}></p>
      ) : (
        <></>
      )}
      <div
        className={alertModalData.onoff ? `alertModal2 ` : `alertModal2 off`}
      >
        {alertModalData.title && (
          <p className={`body_medium title`}>{alertModalData.title}</p>
        )}
        <div className={`caption1_regular text`}>
          {parser(`<p>${alertModalData.text}</p>`)}
        </div>
        <div className={`btns`}>
          {alertModalData.btn1Text && (
            <p className={`btn1`}>
              <RecatangleTextBtn
                text={alertModalData.btn1Text}
                sizeClass="normal"
                colorClass="gray"
                onClick={leftFunc}
              />
            </p>
          )}
          <p className={`btn2`}>
            <RecatangleTextBtn
              text={alertModalData.btn2Text}
              sizeClass="normal"
              onClick={rightFunc}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default AlertModal2;
