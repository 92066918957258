import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./ExerciseDay.scss";

import { ReactComponent as ChevroCheckIcon } from "assets/svg/ic_chevron_check.svg";
import { ReactComponent as ChevronDownIcon } from "assets/svg/ic_chevron_down.svg";
import { ReactComponent as ChevronRightIcon } from "assets/svg/ic_chevron_right.svg";

import DayBtn from "components/butttons/dayBtn/DayBtn";
import ExerciseKind from "../exerciseKind/ExerciseKind";

import { stringFormat } from "utils/commons";

const ExerciseDay = ({ ...props }) => {
  const { t } = useTranslation();
  const { exData, data, open, week, programDatas, countTypeArr, exCode } =
    props;
  // const [defaultDay, setDay] = useState(2);
  const defaultDay = 2;
  const [doneData, setDoneData] = useState(0);
  const [exDataState, setExDataState] = useState<
    (ExerKindType1 | ExerKindType2)[] | null
  >(null);
  const [groupOpenArr, setGroupOpenArr] = useState<string[]>([]);

  useEffect(() => {
    let count = data?.filter((el: string) => "1" === el).length;
    setDoneData(count);
  }, [data]);

  useEffect(() => {
    if (open?.day) setExDataState(exData[open?.day - 1]);
  }, [open]);

  const hendleGroupOepnArr = (openIndex: string) => {
    let newFilterArr: string[] = [];
    const groupOepnArr: string[] = groupOpenArr;

    if (groupOepnArr?.indexOf(openIndex) > -1) {
      newFilterArr = groupOepnArr.filter(
        (element: any) => element !== openIndex
      );
    } else {
      newFilterArr = [...groupOepnArr, openIndex];
    }
    setGroupOpenArr(newFilterArr);
  };

  return (
    <div className={open?.week === week ? `exerciseDay open` : `exerciseDay`}>
      <div className={`topNav`}>
        <div className={`topNavCheck`}>
          <p className={week ? `h2_medium title` : `h2_medium title no_data`}>
            {week &&
              stringFormat(t("ExercisePage_ContentDetail_Week_Title"), week)}
          </p>
          {doneData === props.daysAWeek ? (
            <i className={`checkIcon`}>
              <ChevroCheckIcon />
            </i>
          ) : (
            <span className={`checkNum`}>
              ({props.daysAWeek - doneData}/{props.daysAWeek})
            </span>
          )}
        </div>
        <div className={`dayNav`}>
          {data ? (
            data.map((day: number, index: number) => (
              <div
                key={`day_${week}_${day}_${index}`}
                className={defaultDay === day ? `dayBtnBox open` : `dayBtnBox`}
                onClick={() => {
                  props.dayClickFunc(week, index + 1);
                  setGroupOpenArr([]);
                }}
              >
                <DayBtn
                  day={index + 1}
                  open={open.day === index + 1 && open.week === week}
                  today={
                    props.today.day === index + 1 && props.today.week === week
                  }
                  on={day === 1 ? true : false}
                  exCode={exCode}
                  usridx={props.usridx}
                  week={open.week}
                  creatorIdx={programDatas?.creatorIdx}
                  category={programDatas?.category}
                />
              </div>
            ))
          ) : (
            <div className={`dayBtnBox`}>
              <p className={`no_data`}></p>
            </div>
          )}
        </div>
      </div>
      <div className={`exerKindArea`}>
        {exDataState?.map(
          (data: ExerKindType1 | ExerKindType2 | any, index: number) => (
            <React.Fragment key={`weekday_${week}_${index}`}>
              {!data?.type && data?.type !== "group" ? (
                <div
                  className={
                    exDataState.length - 1 === index
                      ? `exerKindSet lineNone`
                      : `exerKindSet`
                  }
                >
                  <ExerciseKind
                    data={data}
                    programDatas={programDatas}
                    countTypeArr={countTypeArr}
                  />
                </div>
              ) : (
                <div
                  className={
                    groupOpenArr.indexOf(`${week}_${index}`) > -1
                      ? `exerGroupSet open`
                      : `exerGroupSet`
                  }
                  key={`weekday_${week}_${index}`}
                >
                  <div className={`exerGoupTop`}>
                    <p
                      className={`chevron`}
                      onClick={() => hendleGroupOepnArr(`${week}_${index}`)}
                    >
                      {groupOpenArr.indexOf(`${week}_${index}`) > -1 ? (
                        <ChevronDownIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </p>
                    <div className={`exerciseKindBorder`}>
                      <div className={`exerciseKindLeft`}>
                        <p className={`kindImg`}>
                          <img
                            src={`${data.groupList[0]?.exerciseImage}`}
                            alt="kindImg"
                          />
                        </p>
                        <p className={`body_medium kindName`}>
                          {t(data.groupTitle)}
                        </p>
                      </div>
                      <div className={`exerciseKindInputArea number`}>
                        {data.groupType === 1 && (
                          <>
                            <p className={`caption1_regular`}>
                              {t(
                                "ExerciseProgramMaking_Composition_InputTitle_1"
                              )}
                            </p>
                            <p className={`caption3_medium countValue`}>
                              {data.groupList[0]?.set}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {groupOpenArr.indexOf(`${week}_${index}`) > -1 &&
                    data.groupList.map((group: any) => (
                      <div className={`groupSet`}>
                        <ExerciseKind
                          type="group"
                          data={group}
                          programDatas={programDatas}
                          countTypeArr={countTypeArr}
                          groupType={data.groupType}
                        />
                      </div>
                    ))}
                </div>
              )}
            </React.Fragment>
          )
        )}
      </div>
    </div>
  );
};

export default ExerciseDay;
