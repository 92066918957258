import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
//icon
import { ReactComponent as EyeIconFill } from "assets/svg/ic_eye_filled.svg";
import { ReactComponent as DelectCircleIconFill } from "assets/svg/ic_delete_circle_filled.svg";
import { ReactComponent as EyeOffIconFill } from "assets/svg/ic_eye_off_filled.svg";
import qr1 from "assets/img/qr_ios.png";
import qr2 from "assets/img/qr_android.png";
//scss
import "./Login.scss";

//component
import RectangelTextField from "components/fields/rectangelTextField/RectangelTextField";
import RecatangleTextBtn from "components/butttons/recatangleTextBtn/RecatangleTextBtn";
import GrandientTitle from "components/heading/title/GrandientTitle";
import EndBtn from "components/butttons/endBtn/EndBtn";
//store
import { RootState } from "modules";
import { set_alert_modal, set_dorman_model } from "modules/common";
import {
  set_auto_login,
  set_token,
  set_studio_programs,
  set_category_list,
  set_company_idx,
} from "modules/local";

//global
import { postApiPrivate } from "apis/api";
import { onLoginSuccess } from "apis/loginApi";
import { cAPIMessagePack } from "utils/cAPI";

const Login = () => {
  const { t, i18n } = useTranslation();
  const store = useSelector((state: RootState) => state);
  const usridx = store.local.usridx;
  const token = store.local.token;
  const programidx = store.local.programidx;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPassType, setPassType] = useState(true);
  const [isLogin, setLogin] = useState({
    email: "",
    password: "",
  });
  const [isAuto, setAuto] = useState(false);
  useEffect(() => {
    if (usridx > 0 && token) navigate("/");
  }, [usridx, token]);

  useEffect(() => {
    dispatch(set_auto_login(isAuto));
  }, [isAuto]);

  useEffect(() => {
    if (programidx) dispatch(set_auto_login(isAuto));
  }, [programidx]);

  const changeLoginFunc = (type: string, value: string) => {
    setLogin({ ...isLogin, [type]: value });
  };

  const loginApi = () => {
    const pathname = "user/login";
    const param = {
      usremail: isLogin.email,
      usrpw: isLogin.password,
      auto: isAuto,
      programidx: programidx,
    };

    postApiPrivate(pathname, param, (response: any) => {
      if (response?.result === "success") {
        const authenticatetoken = response?.authenticatetoken;
        const userinfo = response?.userinfo;
        const accessToken = authenticatetoken.jwt;
        const responseData = {
          data: {
            authenticatetoken: {
              jwt: accessToken,
              usrIdx: userinfo.usridx,
              auto: isAuto,
            },
            programidx: programidx,
          },
        };

        onLoginSuccess(responseData, null);
        dispatch(set_token(accessToken));
        dispatch(set_studio_programs(response?.libraryGroup));
        dispatch(set_category_list(response?.categorylist));
        dispatch(set_company_idx(response?.companyIdx));

        if (response?.companyIdx !== 0) {
          cAPIMessagePack(
            `exbodyKiosk:?companyidx=${response?.companyIdx}&epidx=0`
          );
        }

        if (window.history.length < 3 || window.history.length === 50) {
          navigate("/");
        } else {
          navigate(-1);
        }
      } else if (response?.result === "failed") {
        if (response?.errtype === "no exist email") {
          const alertModal = {
            onoff: true,
            title: null,
            text: t("Kiosk_SignIn_Err_Email_Pop_1"),
          };
          dispatch(set_alert_modal(alertModal));
        } else if (response?.errtype === "incorrect pw") {
          const alertModal = {
            onoff: true,
            title: null,
            text: t("Kiosk_SignIn_Err_Password_Pop_1"),
          };
          dispatch(set_alert_modal(alertModal));
        } else if (response?.errtype === "dormant account") {
          const modalData = {
            onoff: true,
            last: response.logindate,
            dorman: response.dormantdate,
            usridx: response.usridx,
          };
          dispatch(set_dorman_model(modalData));
        } else {
          const alertModal = {
            onoff: true,
            title: null,
            text: t("Kiosk_SignIn_Retry_Pop"),
          };
          dispatch(set_alert_modal(alertModal));
        }
      } else {
        console.log(pathname, response?.errmsg);
      }
    });
  };

  return (
    <div className={`backgroud  login`}>
      {/* animationBack */}
      <div className={`appJoin`}>
        <p className={`appJoinTitle h3_blod`}>
          {!window.location.origin.includes("kiosk.gym") &&
            !window.location.origin.includes("qa") &&
            `kiosk dev ${i18n.language} / search : ${localStorage.getItem(
              "language"
            )} / programidx : ${programidx}/ search : ${
              window.location.search
            }/ `}
          {window.location.origin.includes("qa") &&
            `kiosk QA ${i18n.language} / search : ${localStorage.getItem(
              "language"
            )}/ programidx : ${programidx}/ search : ${
              window.location.search
            }/ `}
          {t("Kiosk_Phone_SignUp_Announcement_Text")}
        </p>
        <p className={`qrs`}>
          <img src={qr1} alt="app QR" />
          <img src={qr2} alt="app QR" />
        </p>
      </div>
      <div className={`animationContainer form`}>
        <div className={`fromConainer`}>
          <p className={`title`}>
            <GrandientTitle>{t("LoginPage_TitleName")}</GrandientTitle>
          </p>
          <div className={`loginInputArea`}>
            <span className={`caption1_medium label`}>
              {t("LoginPage_InputTitle_1")}
            </span>
            <RectangelTextField
              // width="400px"
              placeholder=""
              value={isLogin.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeLoginFunc("email", e.target.value)
              }
            >
              <i
                className={`delectBtn`}
                onClick={() => changeLoginFunc("email", "")}
              >
                <DelectCircleIconFill
                  width="24px"
                  height="24px"
                  fill="#505050"
                />
              </i>
            </RectangelTextField>
          </div>
          <div className={`loginInputArea`}>
            <span className={`caption1_medium label`}>
              {t("LoginPage_InputTitle_2")}
            </span>
            <RectangelTextField
              // width="400px"
              placeholder=""
              value={isLogin.password}
              type={isPassType ? "password" : "text"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeLoginFunc("password", e.target.value)
              }
              apiFunc={loginApi}
            >
              <>
                <i
                  className={`passLookBtn`}
                  onClick={() => setPassType(!isPassType)}
                >
                  {isPassType ? (
                    <EyeIconFill width="24px" height="24px" fill="#e2e2e2" />
                  ) : (
                    <EyeOffIconFill width="24px" height="24px" fill="#e2e2e2" />
                  )}
                </i>
                <i
                  className={`delectBtn`}
                  onClick={() => changeLoginFunc("password", "")}
                >
                  <DelectCircleIconFill
                    width="24px"
                    height="24px"
                    fill="#505050"
                  />
                </i>
              </>
            </RectangelTextField>
          </div>
          <p className={`loginBtn`} onClick={loginApi}>
            <RecatangleTextBtn text={t("LoginPage_Btn_1")} />
          </p>
        </div>
      </div>
      <EndBtn />
    </div>
  );
};

export default Login;
