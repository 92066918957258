import styled from "styled-components";
import { font } from "assets/styled/fonts";
import { root } from "assets/styled/colors";

interface SideNavProps {
  open: boolean;
}

export const SideNav = styled.div<SideNavProps>`
  width: 239px;
  height: 100vh;
  padding: 0 24px;
  background-image: linear-gradient(
    to bottom,
    var(--dark-grey-20) 0%,
    #0d0d0d 100%
  );
  position: fixed;
  top: 0;
  right: ${(props) => (props.open ? `0px` : `-1000px`)};
  z-index: 100;
  transition: all 0.5s;
`;

export const Close = styled.p`
  padding: 24px 0;
  text-align: center;
  ${font.h4_regular};
  color: ${root.darkgrey80};
  border-bottom: 1px solid ${root.darkgrey30};
  cursor: pointer;
`;

export const BtnArea = styled.div`
  width: 100%;
  height: calc(100vh - 351px);
  // height:calc(100vh - 278px);
`;

export const Btn = styled.p`
  padding: 24px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-bottom: 1px solid ${root.darkgrey30};
  &:last-child {
    border-bottom: none;
  }
  svg {
    fill: ${root.darkgrey80};
    width: 24px;
    height: 24px;
  }
`;

export const Text = styled.span`
  ${font.h2_medium};
  color: ${root.darkgrey80};
  display: inline-block;
  flex: 1;
`;

export const Info = styled.div`
  padding: 23px 0;
  border-top: 1px solid ${root.darkgrey30};
`;

export const Center = styled.p`
  ${font.h4_regular};
  color: ${root.darkgrey60};
`;

export const Tell = styled.p`
  ${font.h2_bold};
  color: ${root.darkgrey80};
  margin: 8px 0 24px;
`;

export const Terms = styled.p`
  margin-bottom: 12px;
  a {
    ${font.body_regular};
    color: ${root.darkgrey60};
  }
`;

export const Copy = styled.p`
  ${font.caption1_regular};
  color: ${root.darkgrey60};
`;
