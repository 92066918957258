import styled from "styled-components";
import { font } from "assets/styled/fonts";

export const EndBtn = styled.p`
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 8px;
  position: fixed;
  bottom: 56px;
  right: 56px;
  ${font.h3_medium};
  cursor: pointer;
`;
