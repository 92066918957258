import { onlyNumberFunc } from "./validate";
import { t } from "i18next";

//스크롤 위치 변경
export const scrollTopFunc = (id: string) => {
  const idElement = document.getElementById(id);
  const elementTop = idElement?.getBoundingClientRect().top;

  if (elementTop) {
    window.scroll(0, Number(elementTop - 200));
  }
};

//string format에 글자 바꾸기
export const stringFormat = (string: string, match: any) => {
  let newString = "";
  const matchString = match ? match : "";

  if (string.indexOf("[{0}]") > -1) {
    newString = string.replace("[{0}]", matchString);
  } else if (string.indexOf("[0]") > -1) {
    newString = string.replace("[0]", matchString);
  } else if (string.indexOf("{0}") > -1) {
    newString = string.replace("{0}", matchString);
  } else if (string.indexOf("0") > -1) {
    newString = string.replace("0", matchString);
  }

  return newString;
};

//string format에 글자 바꾸기
export const stringFormatArr = (string: string, matchs: any) => {
  if (matchs.length > 0) {
    let newString = string;
    for (let i = 0; i < matchs.length; i++) {
      if (string.indexOf(`{${i}}`) > -1) {
        newString = newString.replace(`{${i}}`, matchs[i]);
      } else if (string.indexOf(`${i}`) > -1) {
        newString = newString.replace(`${i}`, matchs[i]);
      }
    }
    return newString;
  }
};

//string format에 띄어 쓰기 br 태그로  바꾸기
export const stringFormatBr = (string: any) => {
  return string.replace(/(?:\r\n|\r|\n)/g, <br />);
};

export const setToolsData = (data: any, data1: any) => {
  let newTools: any = {};
  data1.filter((t: any) => {
    if (data.includes(t)) {
      newTools[`${t.id}`] = 1;
    } else {
      newTools[`${t.id}`] = 0;
    }
  });
  return newTools;
};

export const handleToolIndexData = (data: any) => {
  let newTools = [];
  for (let tool of data) {
    const i = tool.index; //|| tool.value
    newTools.push(i);
  }
  return newTools;
};

export const dataArrSetting = (number: number, list: any) => {
  const datas: any = [];
  const newIndex: any = [];
  for (let d = 0; d < list.length; d++) {
    let index = Math.floor(d / number);
    if (d % number == 0) {
      datas[index] = [];
    }
    datas[index].push(list[d]);
  }

  return datas;
};

export const hoursSeconds = (seconds: number) => {
  const hour = seconds / 3600 < 1 ? 0 : Math.floor(seconds / 3600);
  const min = (seconds % 3600) / 60 < 1 ? 0 : Math.floor((seconds % 3600) / 60);

  return [hour, min];
};

//다국어에서 색상 바꾸기
export const colorTagChange = (text: string) => {
  const colorArr = [
    {
      color: "#569CF2",
      class: "blue",
    },
    {
      color: "#2F9D27",
      class: "green",
    },
    {
      color: "#FF0000",
      class: "red",
    },
    {
      color: "#FFF2CC",
      class: "ivory",
    },
  ];

  let newText = "";

  if (text.indexOf("<color=") > -1) {
    //color태그가 있는지 판단하기
    const color = text.slice(
      text.indexOf("<color=") + 7, //<color= 의 자리수를 찾아 color 7자리 찾기
      text.indexOf("<color=") + 14
    );
    const colorClass = colorArr.filter((element) => element.color === color); //정의해둔 colorArr에서 사용할 color값 찾기
    newText = text
      .replaceAll(`<color=${color}>`, `<span class="${colorClass[0]?.class}">`) //color태그 span 태그로 변경하기
      .replaceAll("</color>", "</span>");
  }

  return newText;
};

//문자열 날짜 분리해서 가져오기
export const setNewDateFunc = (date: any) => {
  const newDate = new Date(date);
  const isNewDate = {
    year: newDate.getFullYear(),
    month: Number(("0" + (newDate.getMonth() + 1)).slice(-2)),
    day: Number(("0" + newDate.getDate()).slice(-2)),
    hours: Number(("0" + newDate.getHours()).slice(-2)),
    minutes: Number(("0" + newDate.getMinutes()).slice(-2)),
    seconds: Number(("0" + newDate.getSeconds()).slice(-2)),
  };

  return isNewDate;
};

//선택한 데이터 있으면 빼고, 없으면 넣고
export const selectDatasFunc = (select: any, datas: any) => {
  let newAddTagArr: any = [];
  const selectArr = datas;
  if (selectArr?.indexOf(select) > -1) {
    newAddTagArr = selectArr.filter((element: any) => element !== select);
  } else {
    newAddTagArr = [...datas, select];
  }
  return newAddTagArr;
};

//현재시간과 비교시간 분 차이
export const gapMinutesFunc = (sttDt: any) => {
  //시간
  const now = new Date();
  const sttDTime = new Date(sttDt);

  const sttMonth = sttDTime.getMonth() + 1;
  const lastDay = new Date(now.getFullYear(), sttMonth, 0).getDate();

  //현재시간
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const houurs = now.getHours();
  const minutes = now.getMinutes();

  //비교시간
  const sttYear = sttDTime.getFullYear();
  const sttDay = sttDTime.getDate();
  const sttHouurs = sttDTime.getHours();
  const sttMinutes = sttDTime.getMinutes();

  const date1 = new Date(year, month, day, houurs, minutes);
  const date2 = new Date(sttYear, sttMonth, sttDay, sttHouurs, sttMinutes);
  const elapsedMSec = date2.getTime() - date1.getTime();
  let elapsedMin: any = elapsedMSec / 1000 / 60;
  return parseInt(elapsedMin);
};

//두시간 비교
export const gapMinutes2Func = (sttDt: any, nowsttDt: any) => {
  //시간
  const now = new Date(nowsttDt);
  const sttDTime = new Date(sttDt);

  //현재시간
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const houurs = now.getHours();
  const minutes = now.getMinutes();

  //비교시간
  const sttYear = sttDTime.getFullYear();
  const sttMonth = sttDTime.getMonth() + 1;
  const sttDay = sttDTime.getDate();
  const sttHouurs = sttDTime.getHours();
  const sttMinutes = sttDTime.getMinutes();

  const date1 = new Date(year, month, day, houurs, minutes);
  const date2 = new Date(sttYear, sttMonth, sttDay, sttHouurs, sttMinutes);

  const elapsedMSec = date2.getTime() - date1.getTime();
  let elapsedMin: any = elapsedMSec / 1000 / 60;

  return parseInt(elapsedMin);
};

//받은 kinds, keywords 데이터 arr로 변환
export const objectChageStringArrFunc = (datas: any) => {
  const newArr = [];
  for (let [key, value] of Object.entries(datas)) {
    if (value === 1) newArr.push(key);
  }
  return newArr;
};

//pt, gx 가격 셋팅
export const monthPayFunc = (trainerprice: any) => {
  const nextDay = new Date().getMonth() + 2;
  const dateTitle = document.querySelector(".fc-toolbar-title")?.innerHTML;
  if (dateTitle) {
    let dateTitleSplit: any = dateTitle?.split(" ");
    let nowM = Number(onlyNumberFunc(dateTitleSplit[1])?.padStart(2, "0"));

    if (dateTitleSplit.length === 7) {
      dateTitleSplit.splice(4, 1);
    }

    if (dateTitleSplit.length === 6) {
      nowM = Number(onlyNumberFunc(dateTitleSplit[4])?.padStart(2, "0"));
    }

    let price = { pt: trainerprice?.nowptprice, gx: trainerprice?.nowgxprice };
    if (nextDay === nowM) {
      price = { pt: trainerprice?.nextptprice, gx: trainerprice?.nextgxprice };
    }

    return price;
  }
};

//트레이너 pt, gx 예약, 결제 가격 리스트
export const payCompareFunc = (
  priceArr: any,
  price: number | string,
  calendaridx: number
) => {
  let checkKey = true;
  if (priceArr && priceArr?.length > 0) {
    for (let arr of priceArr) {
      const keyArr = Object.keys(arr);
      const key = keyArr[0];
      if (Number(key) === price) {
        arr[key] = selectDatasFunc(calendaridx, arr[key]); // if( arr[key].indexOf(calendaridx) < 0) arr[key].push(calendaridx);
        checkKey = false;
      }
    }

    if (checkKey) {
      priceArr.push({ [price]: [calendaridx] });
    }
  } else {
    priceArr.push({ [price]: [calendaridx] });
  }

  return priceArr;
};

export const capitalize = (sentence: string) => {
  sentence = sentence.toLocaleLowerCase();
  return sentence && sentence[0].toUpperCase() + sentence.slice(1);
};

//천단위 콤마
export const thousandsFunc = (pay: number | string) => {
  return pay?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//날짜 오늘, 내일 인지 확인
export const isToday = (curDate: string) => {
  const dataDate = setNewDateFunc(curDate);
  const today = setNewDateFunc(new Date());

  let dateText = `${dataDate.month}월 ${dataDate.day}일 ${getDate(curDate)}`;
  if (dataDate.year === today.year && dataDate.month === today.month) {
    if (dataDate.day === today.day) {
      dateText = "오늘";
    } else if (dataDate.day - today.day === 1) {
      dateText = "내일";
    }
  }
  return dateText;
};

//날짜문자열 형식은 자유로운 편
export const getDate = (curDate: string) => {
  // var week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
  var week = [
    "Trainer_Page_Setting_Calendar_Pop_Sun",
    "Trainer_Page_Setting_Calendar_Pop_Mon",
    "Trainer_Page_Setting_Calendar_Pop_Tue",
    "Trainer_Page_Setting_Calendar_Pop_Wed",
    "Trainer_Page_Setting_Calendar_Pop_Thu",
    "Trainer_Page_Setting_Calendar_Pop_Fri",
    "Trainer_Page_Setting_Calendar_Pop_Sat",
  ];
  var dayOfWeek = week[new Date(curDate).getDay()];

  return t(dayOfWeek);
};

export const divisionTimeFunc = (curDate: string) => {
  const time = Number(curDate.slice(0, 2));

  let divisionTime = `오전 ${curDate.slice(0, 5)}`;
  if (time > 11) {
    divisionTime = `오후 ${time - 12}${curDate.slice(2, 5)}`;
  }

  return divisionTime;
};

export const toHHMMSS = (curTime: string) => {
  var myNum = parseInt(curTime, 10);
  var hours = Math.floor(myNum / 3600);
  var minutes = Math.floor((myNum - hours * 3600) / 60);
  var seconds = myNum - hours * 3600 - minutes * 60;

  return { hours: hours, minutes: minutes, seconds: seconds };
};

export const windowSearch = () => {
  let search = window.location.search;
  search = search.replace("?", "");

  return search;
};

//url에서 page값 가져오기
export const searchGetPage = () => {
  if (window.location.search && window.location.search !== "") {
    const search = window.location.search?.split("&");

    const page = search?.filter((text: any) => text.indexOf("page") > -1);
    const clickPage = page[0]?.split("page=")[1]
      ? Number(page[0].split("page=")[1])
      : 1;

    return clickPage;
  } else {
    return 1;
  }
};

//page선택시 url 셋팅
export const pageSearchAddFunc = (page: number) => {
  const pathname = window.location.pathname; //url에 pathname 가져오기
  let search: string | string[] = window.location.search; //url에 search값 가져오기
  let newSearch = ""; //page값을 제외한 search값 문자열 담을 변수

  if (search) {
    //search값이 있는지 판단하기
    search = search.split("&"); //search값에 "&"로 제외하여 배열로 만들기
    //search에 page값이 아닌 다른 search keyword를 string으로 연결하기
    for (let text of search) {
      if (text.indexOf("page=") === -1) {
        const newText = text.indexOf("?") > -1 ? text : `&${text}`;
        newSearch += newText;
      }
    }
  }
  //newSearch값이 ""이면 pathname에 "?"를 붙혀서 셋팅 아닐경우 "&"으로 붙히기
  const url =
    newSearch === ""
      ? `${pathname}${newSearch}?page=${page}`
      : `${pathname}${newSearch}&page=${page}`;
  return url;
};

//url에서 원하는 search값 가져오기
export const searchGet = (cate: string) => {
  const urlSearch = window.location.search; //url에 search값 가져오기
  if (urlSearch && urlSearch !== "") {
    //search값이 있는지 판단하기
    const search = urlSearch?.split("&"); //search값에 "&"로 제외하여 배열로 만들기
    const getCateArr = search?.filter((text: any) => text.indexOf(cate) > -1); //배열 중에 원하는(cate)값이 포함된 배열 가져오기
    const getCate = getCateArr[0]?.split(`${cate}=`)[1]; //배열에서 cate포함된 = 문자열 잘라서 순수 search값 추출하기

    return getCate && decodeURI(getCate);
  }
};

//원하는 검색 값 url에 셋팅
export const searchAddFunc = (
  key: string,
  value: number | string,
  searchUrl: string | null
) => {
  const pathname = window.location.pathname;
  let search: string | string[] = searchUrl
    ? searchUrl?.split("&")
    : window.location.search?.split("&"); //url에 search값 가져오기
  let newSearch = `${pathname}?${key}=${value}`; //page값을 제외한 search값 문자열 담을 변수

  if (search && search[0] !== "" && search.length > 0) {
    //search값이 있는지 판단하기
    //search에 page값이 아닌 다른 search keyword를 string으로 연결하기
    const isLargeNumber = (element: any) => element.indexOf(`${key}=`) > -1;
    let index = search.findIndex(isLargeNumber);

    if (search[index]) {
      const splitSearch = search[index].split("=");
      splitSearch[1] = value.toString();
      search[index] = splitSearch.join("=");
      newSearch = search.join("&");
    } else {
      newSearch = search.join("&") + `&${key}=${value}`;
    }
  }

  //newSearch값이 ""이면 pathname에 "?"를 붙혀서 셋팅 아닐경우 "&"으로 붙히기
  const url = newSearch;

  return url;
};

//배열에 있는 값 url형태로 만들기
export const handelArrSettingUrl = (selectDatas: object) => {
  let searchUrl = "";
  for (let [key, value] of Object.entries(selectDatas)) {
    const valueArr = typeof value === "object" ? value.sort().join() : value;
    searchUrl = searchUrl + `&${key}=${valueArr}`;
  }
  return searchUrl;
};

//심박수 정보
export const handleHeartRateData = (datas: any, age: number) => {
  const zoneArr = [
    { min: 0, max: 60, grad: 1 },
    { min: 61, max: 70, grad: 2 },
    { min: 71, max: 83, grad: 3 },
    { min: 84, max: 91, grad: 4 },
    { min: 92, max: 200, grad: 5 },
  ];

  const hrDataArr = [];
  const newData = {
    totalPoint: 0,
    avgHR: 0,
    maxHR: 0,
    avgPer: 0,
    maxPer: 0,
  };
  let totalPoint = 0;
  let avgHR = 0;
  let maxHR = 0;
  let avgPer = 0;
  let maxPer = 0;

  for (let data of datas) {
    const hrMax = 208 - 0.7 * age;
    const hrMaxPer = (data.max / hrMax) * 100;
    if (data.max >= maxHR) maxHR = data.max;
    avgHR = avgHR + data.max;

    for (let zone of zoneArr) {
      const isGrad = inRange(hrMaxPer, zone.min, zone.max);

      if (isGrad) {
        const hrDataPoint = {
          data,
          hrMaxPer,
          grad: zone.grad,
        };
        if (zone.grad > 3) totalPoint = totalPoint + 1;
        if (hrMaxPer >= maxPer) maxPer = hrMaxPer;
        avgPer = avgPer + hrMaxPer;
        hrDataArr.push(hrDataPoint);
      }
    }
  }

  avgHR = Math.ceil(avgHR / datas.length);
  avgPer = Math.ceil(avgPer / datas.length);
  maxPer = Math.ceil(maxPer);
  const gradTimeArr = handleGradTime(hrDataArr);
  return { totalPoint, hrDataArr, gradTimeArr, avgHR, maxHR, avgPer, maxPer };
};

export const inRange = (x: number, min: number, max: number) => {
  return x >= min && x <= max;
};

/*
 const chartData = [
  { text: "저강도", time: 1, percent: 0 },
  { text: "저/중강도", time: 3, percent: 20 },
  { text: "중강도", time: 7, percent: 50 },
  { text: "중/고강도", time: 3, percent: 60 },
  { text: "고강도", time: 6, percent: 90 },
 ];
 */
export const handleGradTime = (hrDataArr: any) => {
  const gradTimeArr = [
    { text: "저강도", time: 0, percent: 0 },
    { text: "저/중강도", time: 0, percent: 0 },
    { text: "중강도", time: 0, percent: 0 },
    { text: "중/고강도", time: 0, percent: 0 },
    { text: "고강도", time: 0, percent: 0 },
  ];

  if (hrDataArr?.length > 0) {
    for (let data of hrDataArr) {
      gradTimeArr[data.grad - 1].time = gradTimeArr[data.grad - 1].time + 1;
      gradTimeArr[data.grad - 1].percent =
        (gradTimeArr[data.grad - 1].time / hrDataArr.length) * 100;
    }
  }
  return gradTimeArr;
};

//문자배열을 숫자배열로 변경하기
export const toNumbers = (arr: string[]) => arr.map(Number);

export const handleYYYYMMDD = (date: string) => {
  const day = date ? date.replaceAll("-", ".").slice(0, 10) : "0000.00.00";
  return day;
};

//휴대폰번호 자동 하이픈 추가
export const phonenumberFunc = (phonenumber: string) => {
  const regex = /[^0-9]/g;
  const result = phonenumber.replace(regex, "");
  return result;
};
