import "./RoundBtn.scss";

interface Props {
  text?: string; //버튼에 들어갈 글자
  icon?: null; //버튼에 들어갈 iocn
  typeClassName?: string; //버튼 형태에 따른 class  :: line
  select?: string;
  onClick?: () => void;
  style?: any;
}
const RoundBtn = ({
  text = "Button", //버튼에 들어갈 글자
  icon = null, //버튼에 들어갈 iocn
  typeClassName = "", //버튼 형태에 따른 class  :: line
  select = "",
  onClick,
  style,
}: Props) => {
  return (
    <button
      className={`caption3_regular roundBtn ${typeClassName} ${select}`}
      onClick={onClick}
      style={style}
    >
      {icon && <i className={`svg`}>{icon}</i>}
      {text}
    </button>
  );
};

export default RoundBtn;
