import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "modules";
import { useTranslation } from "react-i18next";
import parser from "html-react-parser";
import "./AlertModifyModal.scss";
import RecatangleTextBtn from "components/butttons/recatangleTextBtn/RecatangleTextBtn";
import { set_alert_modify_modal } from "modules/common";

const AlertModifyModal = () => {
  const { t } = useTranslation();
  const store = useSelector((state: RootState) => state);
  const commonStroe = store.common;
  const alertModalData = commonStroe.alertModifyModal;
  const dispath = useDispatch();
  const [modifyText, setModifyText] = useState("");

  const leftFunc = () => {
    setModifyText("");
    dispath(
      set_alert_modify_modal({
        onoff: false,
        title: null,
        text: null,
      })
    );
  };

  const rightFunc = () => {
    alertModalData.okFunc(2, modifyText);
    setModifyText("");
    dispath(
      set_alert_modify_modal({
        onoff: false,
        title: null,
        text: null,
      })
    );
  };

  return (
    <>
      {alertModalData.onoff ? <p className={`dimmed`}></p> : <></>}
      <div
        className={
          alertModalData.onoff ? `alertModifyModal` : `alertModifyModal off`
        }
      >
        {alertModalData.title && (
          <p className={`body_medium title`}>{alertModalData.title}</p>
        )}
        <div className={`caption1_regular text`}>
          {parser(`<p>${alertModalData.text}</p>`)}
        </div>
        <div className={`modifyArea`}>
          <p className={`modifyTop`}>
            <span className={`caption1_medium modifyTitle`}>
              {t("ExerciseProgramJudgePage_JudgeFailCheckPopup_InputTitle")}
            </span>
            <span className={`caption1_regular textCount`}>
              {modifyText.length} / 30
            </span>
          </p>
          <p className={`modifyInput`}>
            <input
              className={`caption1_regular`}
              type="text"
              maxLength={30}
              value={modifyText}
              title="modifyInfo"
              onChange={(e) => setModifyText(e.target.value)}
            />
          </p>
        </div>
        <div className={`btns`}>
          <p className={`btn1`}>
            <RecatangleTextBtn
              text={alertModalData.btn1Text}
              sizeClass="normal"
              colorClass="gray"
              onClick={leftFunc}
            />
          </p>
          <p className={`btn2`}>
            <RecatangleTextBtn
              text={alertModalData.btn2Text}
              sizeClass="normal"
              onClick={rightFunc}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default AlertModifyModal;
