import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//img
import { ReactComponent as ListLinedIcon } from "assets/svg/ic_list_lined.svg";
import { ReactComponent as AddCircleLinedIcon } from "assets/svg/ic_add_circle_lined.svg";

//style
import "./HomeStudio.scss";

//global
import { searchGet } from "utils/commons";
import { RootState } from "modules";
import { cAPIMessagePack } from "utils/cAPI";

//component
import RecatangleTextBtn from "components/butttons/recatangleTextBtn/RecatangleTextBtn";
import ProduceTable from "../produceTable/ProduceTable";
// import UploadTable from "../uploadTable/UploadTable";
import { Link } from "react-router-dom";
import FirstStudio from "../firstStudio/FirstStudio";

//api
// import { useStudioUploadstate } from "apis/studioApi";

const HomeStudio = () => {
  const { t } = useTranslation();
  const store = useSelector((state: RootState) => state);
  const usridx = store.local.usridx;
  const studioPrograms = store.local.studioPrograms || [1];
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState("pruduce");

  const [isProduceFirst, setProduceFirst] = useState(false);
  const [isUploadeFirst, setUploadeFirst] = useState(false);

  //api
  // const param = {
  //   page: 1,
  //   usridx: usridx, //235,
  //   programState: 4,
  //   order: "descserviceDate",
  // };
  // const useStudioUploadstateApi = useStudioUploadstate(param);

  useEffect(() => {
    return () => {
      setProduceFirst(false);
      setUploadeFirst(false);
    };
  }, []);

  useEffect(() => {
    if (usridx > 0) {
      // useStudioUploadstateApi.refetch();
    } else {
      setProduceFirst(true);
      setUploadeFirst(true);
    }
  }, [usridx]);

  useEffect(() => {
    let cate: string | undefined = searchGet("cate")
      ? searchGet("cate")
      : "pruduce";
    if (cate) setSelectedState(cate);
  }, [window.location.search]);

  const handelStudioProduce = () => {
    if (studioPrograms.length === 1 && studioPrograms[0] === 1) {
      cAPIMessagePack(`exbodyKiosk:?type=3&usridx=${usridx}&datatype=1`);
    } else {
      navigate("/studioProduce");
    }
  };

  return (
    <>
      {isProduceFirst && isUploadeFirst ? (
        <FirstStudio />
      ) : (
        <div className={`homeStudio`}>
          {/* 운동 스튜디오 */}
          <p className={`h2_medium studioTitle`}>
            {t("ExerciseStudioPage_Title")}
          </p>
          <div className={`topBtns`}>
            <div className={`leftBtns`}>
              <p
                className={
                  selectedState === "pruduce" ? `produceBtn on` : `produceBtn`
                }
              >
                <Link to="/studio">
                  <RecatangleTextBtn
                    text={t("ExerciseStudioPage_Tab_1")}
                    colorClass="line"
                  >
                    <ListLinedIcon />
                  </RecatangleTextBtn>
                </Link>
              </p>
            </div>
            <p className={`addNewBtn`}>
              {/* 신규 프로그램 제작 */}
              <RecatangleTextBtn
                text={t("ExerciseStudioPage_Btn1")}
                colorClass="line"
                onClick={handelStudioProduce}
              >
                <AddCircleLinedIcon />
              </RecatangleTextBtn>
            </p>
          </div>
          <div className={`homeStudioContainer`}>
            <ProduceTable setProduceFirst={setProduceFirst} />
          </div>
        </div>
      )}
    </>
  );
};

export default HomeStudio;
