import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { useEffect } from "react";
import {
  handleReloadProgram,
  handleExapparatusLogin,
  sendLanguage,
} from "./utils/unity";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./modules";

import { useNavigate, useLocation } from "react-router-dom";
// import { browserhistory } from "hooks/history";
import { useTranslation } from "react-i18next";

//component
import RouterComponent from "Router";
import AlertModal from "components/modal/alertModal/AlertModal";
import AlertModal2 from "components/modal/alertModal/AlertModal2";
import AlertModifyModal from "components/modal/alertModal/AlertModifyModal";
import DormantAccountModal from "components/modal/dormantAccountModal/DormantAccountModal";
import DormantTermination from "components/modal/dormantTerminationModal/DormantTermination";

//api
import { set_program_idx } from "./modules/local";

const App = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state: RootState) => state);
  const { usridx } = store.local;

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const programidx = searchParams.get("programidx");

  useEffect(() => {
    localStorage.setItem("language", i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (programidx) {
      dispatch(set_program_idx(programidx));
    }
  }, [programidx]);

  useEffect(() => {
    const listenBackEvent = () => {
      const pathname = window.location.pathname;

      if (
        pathname === "/" ||
        ["exercise", "studio", "serviceTerms"].some((substring) =>
          pathname.includes(substring)
        )
      ) {
        if (usridx < 1 || !usridx) {
          return navigate("/login");
        }
      }
    };

    // const unlistenHistoryEvent = browserhistory.listen(() => {
    //   listenBackEvent();
    // });

    // return unlistenHistoryEvent;

    return listenBackEvent;
  }, [navigate, usridx]);

  useEffect(() => {
    (window as any).handleReloadProgram = function (msg: any) {
      handleReloadProgram(msg);
    };

    (window as any).handleExapparatusLogin = function (msg: any) {
      handleExapparatusLogin(msg);
    };

    (window as any).sendLanguage = function (msg: any) {
      sendLanguage(msg);
    };

    (window as any).sendUrl = function (msg: any) {
      navigate(msg);
    };

    // window.handleProgramidx = function (msg) {
    //   handleProgramidx(msg);
    // };

    return () => {
      (window as any).handleReloadProgram = null;
      (window as any).handleExapparatusLogin = null;
      (window as any).sendLanguage = null;
      (window as any).sendUrl = null;
      // window.handleProgramidx = null;
    };
  }, []);

  return (
    <div className={`App ${i18n.language}`} id="App">
      <div className="wrap" id="wrap">
        <RouterComponent />
      </div>
      <AlertModal />
      <AlertModal2 />
      <AlertModifyModal />
      <DormantAccountModal />
      <DormantTermination />
    </div>
  );
};

export default App;
