import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const ko = require("./ko.json");
const en = require("./en.json");
const zh = require("./ch.json");
const ja = require("./ja.json");

const resource = {
  ko: {
    translation: ko,
  },
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
  ja: {
    translation: ja,
  },
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const language = urlParams.get("language");
const localLanguage =
  localStorage.getItem("language") === "ko"
    ? "ko"
    : localStorage.getItem("language");
let lang = language || localLanguage || "en";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resource,
    lng: lang,
    fallbackLng: lang,
    // debug : true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
