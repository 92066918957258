import { useState } from "react";
import "./RectangelTextField.scss";
import RoundBtn from "components/butttons/roundBtn/RoundBtn";

import { ReactComponent as WarningCilcelIconLine } from "assets/svg/ic_warning_circle_lined.svg";
import { ReactComponent as ChevronDownIcon } from "assets/svg/ic_chevron_down.svg";
import { ReactComponent as SearchIconFill } from "assets/svg/ic_search_filled.svg";

const RectangelTextField = ({
  type = "text",
  cate = "",
  placeholder = "Placeholder",
  value = "",
  messageType = "",
  errorMessage = <span></span>,
  confirmMessage = <span></span>,
  ...props
}) => {
  const [isFocus, setFocus] = useState(props?.focus ? props?.focus : false);

  const blurEvent = () => {
    if (props?.focus) {
      if (props?.blurEvent) {
        props?.blurEvent();
        props?.setFocus("");
      } else {
        props?.setFocus("");
      }
    } else {
      if (props?.blurEvent) {
        props?.blurEvent();
        setFocus("");
      } else {
        setFocus("");
      }
    }
  };

  const onkeyDown = (e: React.KeyboardEvent) => {
    if (e.which === 13) {
      props?.apiFunc();
    }
  };

  return (
    <div
      // style={{ width: props?.width }}
      className={
        messageType === "error" || messageType === "errorLine"
          ? `rectangelTextField error`
          : messageType === "confirm"
          ? `rectangelTextField confirm`
          : `rectangelTextField`
      }
    >
      <div
        className={
          !props?.readOnly && props?.focus
            ? props?.focus
              ? `inputArea focus`
              : `inputArea`
            : isFocus && !props?.readOnly
            ? `inputArea focus`
            : `inputArea readOnly`
        }
      >
        <input
          className={`body_regular input`}
          type={type}
          placeholder={placeholder}
          onFocus={
            props?.setFocus
              ? () => props?.setFocus(cate)
              : () => setFocus(!isFocus)
          }
          onBlur={blurEvent}
          value={value}
          onChange={props?.onChange}
          min={props?.min}
          max={props?.max}
          pattern={props?.pattern}
          maxLength={props?.maxLength}
          title={placeholder}
          onKeyDown={props?.apiFunc ? onkeyDown : () => null}
          readOnly={props?.readOnly}
          name={cate}
        />
        {((value && value?.length > 0) || (props && props?.isBtn)) && (
          <>
            {cate === "button" && <RoundBtn />}
            {props?.children && props?.children}
          </>
        )}
        {cate === "search" && (
          <i onClick={() => props?.apiFunc()}>
            <SearchIconFill width="24px" height="24px" />
          </i>
        )}
      </div>
      {messageType && (
        <>
          {(messageType === "error" || messageType === "errorText") && (
            <p className={`caption3_light error`}>
              <WarningCilcelIconLine
                width="16px"
                height="16px"
                fill="#ff3737"
              />
              {errorMessage}
            </p>
          )}
          {messageType === "confirm" && (
            <p className={`caption3_light confirm`}>
              <ChevronDownIcon width="16px" height="16px" fill="#00c88c" />
              {confirmMessage}
            </p>
          )}
          {messageType === "msg" && (
            <p className={`caption3_light msg`}>{errorMessage}</p>
          )}
        </>
      )}
    </div>
  );
};

export default RectangelTextField;
