import { useTranslation } from "react-i18next";
import "./ExerciseDescModal.scss";
import RecatangleTextBtn from "components/butttons/recatangleTextBtn/RecatangleTextBtn";
import CircleProfileIcon from "components/circleProfileIcon/CircleProfileIcon";

const ExerciseDescModal = ({
  onoff = false,
  title = "",
  text = "",
  ...props
}) => {
  const { t } = useTranslation();
  const { desc, desc2, src, movie } = props;

  return (
    <>
      {
        <p
          className={onoff ? "dimmed" : "dimmed none"}
          onClick={props.handleClose}
        ></p>
      }
      <div className={onoff ? `exerciseDescModal off` : `exerciseDescModal`}>
        <div className={`box scroll whiteBackScroll`}>
          <div className={`exerciseProfile`}>
            <CircleProfileIcon src={`${src}`} />
            <p className={`h2_medium title`}>{t(title)}</p>
          </div>
          <div className={`exerciseImg`}>
            {!movie || movie?.toLowerCase() === "none" ? (
              <img src={src} alt="exerciseImg" />
            ) : (
              <video width="476" height="317" src={movie} muted loop autoPlay />
            )}
          </div>
          <div className={`exerciseDescArea`}>
            <div className={`exerciseDesc`}>
              {/* 운동 설명 */}
              <p className={`caption3_medium subTitle`}>
                {t("ExerciseProgramMaking_Info_InputTitle_4")}
              </p>
              <p className={`body_regular`}>{t(desc)}</p>
            </div>
            {desc2 && (
              <div className={`body_regular exerciseDesc exerciseDesc2`}>
                {/* 주의 사항 */}
                <p className={`caption3_medium subTitle`}>
                  {t("ExerciseProgramMaking_Info_InputTitle_5")}
                </p>
                <p className={`caption1_medium red`}>{t(desc2)}</p>
              </div>
            )}
          </div>
        </div>
        <p className={`btn`} onClick={props.handleClose}>
          {/* 확인 */}
          <RecatangleTextBtn text={t("Exercise_Information_Pop_Button")} />
        </p>
      </div>
    </>
  );
};

export default ExerciseDescModal;
