import React from "react";
import { useTranslation } from "react-i18next";
import "./ExerciseInfo.scss";
import ExerciseType from "../exerciseType/ExerciseType";
import ExerciseTypeRectangle from "../exerciseTypeRectangle/ExerciseTypeRectangle";
import { stringFormat } from "utils/commons";

interface Props {
  title?: string;
  time?: string;
  count?: string;
  commonCode?: any[];
  data?: ExTypeRectangleType;
  titleStyle?: React.CSSProperties | undefined;
  infoStyle?: React.CSSProperties | undefined;
  key?: React.Key | null | undefined;
  type?: string;
}

const ExerciseInfo = ({
  title,
  time,
  count,
  commonCode,
  data,
  titleStyle,
  infoStyle,
  type,
  key,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {data ? (
        <div className={`exerciseInfo`}>
          <p className={`h3_medium title`} style={titleStyle}>
            {title ? (
              <span>{title}</span>
            ) : (
              <span className={`no_data`} style={{ width: "50%" }}></span>
            )}
          </p>
          <p className={`info`} style={infoStyle}>
            <span className="caption1_regular">
              {time
                ? stringFormat(t("ExercisePage_Content_OneExerciseTime"), time)
                : stringFormat(t("ExercisePage_Content_OneExerciseTime"), 0)}
            </span>
            <i className={`line`}></i>
            <span className="caption1_regular">
              {count
                ? stringFormat(
                    t("ExercisePage_Content_TotalExerciseTime"),
                    count
                  )
                : stringFormat(t("ExercisePage_Content_TotalExerciseTime"), 0)}
            </span>
          </p>
          {type === "rectangle" ? (
            <ExerciseTypeRectangle commonCode={commonCode} data={data} />
          ) : (
            <ExerciseType commonCode={commonCode} data={data} />
          )}
        </div>
      ) : (
        <div className={`exerciseInfo noData`}>
          <p className={`h4_medium title no_data`} style={titleStyle}></p>
          <p className={`info`} style={infoStyle}>
            <span className="caption1_regular no_data"></span>
          </p>
          {type === "rectangle" ? (
            <ExerciseTypeRectangle commonCode={commonCode} />
          ) : (
            <ExerciseType commonCode={commonCode} />
          )}
        </div>
      )}
    </>
  );
};

export default ExerciseInfo;
