//object 값 업데이트
const updateObject = (oldObject: any, newValues: any) => {
  return Object.assign({}, oldObject, newValues);
};

//action
export const SET_ALERT_MODAL = "SET_ALERT_MODAL";
export const set_alert_modal = (alertModal: any) => {
  return {
    type: SET_ALERT_MODAL,
    param: alertModal,
  };
};

export const SET_ALERT2_MODAL = "SET_ALERT2_MODAL";
export const set_alert2_modal = (alertModal2: any) => {
  return {
    type: SET_ALERT2_MODAL,
    param: alertModal2,
  };
};

export const SET_ALERT_MODIFY_MODAL = "SET_ALERT_MODIFY_MODAL";
export const set_alert_modify_modal = (alertModifyModal: any) => {
  return {
    type: SET_ALERT_MODIFY_MODAL,
    param: alertModifyModal,
  };
};

export const SET_HEADING_DATA = "SET_HEADING_DATA";
export const set_heading_data = (heading: any) => {
  return {
    type: SET_HEADING_DATA,
    param: heading,
  };
};

export const SET_USER_INFO = "SET_USER_INFO";
export const set_user_info = (userInfo: any) => {
  return {
    type: SET_USER_INFO,
    param: userInfo,
  };
};

export const SET_SOCAl_LOGIN = "SET_SOCAl_LOGIN";
export const set_social_login = (socialLogin: any) => {
  return {
    type: SET_SOCAl_LOGIN,
    param: socialLogin,
  };
};

export const SET_TERM_DATA = "SET_TERM_DATA";
export const set_term_data = (term: any) => {
  return {
    type: SET_TERM_DATA,
    param: term,
  };
};

export const SET_STUDIO_TITLE = "SET_STUDIO_TITLE";
export const set_studio_title = (studioTitle: string) => {
  return {
    type: SET_STUDIO_TITLE,
    param: studioTitle,
  };
};

export const SET_BACK_PATH = "SET_BACK_PATH";
export const set_back_path = (backPath: string) => {
  return {
    type: SET_BACK_PATH,
    param: backPath,
  };
};

//id찾기 이메일
export const SET_IDFIND_EMAIL = "SET_IDFIND_EMAIL";
export const set_idfind_email = (idfindEmail: string) => {
  return {
    type: SET_IDFIND_EMAIL,
    param: idfindEmail,
  };
};

//pw찾기 usridx
export const SET_PWFIND_USRIDX = "SET_PWFIND_USRIDX";
export const set_pwfind_usridx = (pwfindUsridx: string) => {
  return {
    type: SET_PWFIND_USRIDX,
    param: pwfindUsridx,
  };
};

export const SET_UNITY_USERIMG = "SET_UNITY_USERIMG";
export const set_unity_userimg = (unityUserimg: any) => {
  return {
    type: SET_UNITY_USERIMG,
    param: unityUserimg,
  };
};

//휴먼계정 팝업
export const SET_DORMAN_MODAL = "SET_DORMAN_MODAL";
export const set_dorman_model = (dormanModel: any) => {
  return {
    type: SET_DORMAN_MODAL,
    param: dormanModel,
  };
};

//휴먼계정 해지 팝업
export const SET_DORMAN_TERMINATION_MODAL = "SET_DORMAN_TERMINATION_MODAL";
export const set_dorman_termination_model = (terminationModel: any) => {
  return {
    type: SET_DORMAN_TERMINATION_MODAL,
    param: terminationModel,
  };
};

//프로그램 갱신 클라 통신
export const SET_PROGRAM_RELOAD = "SET_PROGRAM_RELOAD";
export const set_program_reload = (programReload: any) => {
  return {
    type: SET_PROGRAM_RELOAD,
    param: programReload,
  };
};

type Type = {
  serverip: string;
  serverport: string;
  userInfo: any;
  alertModal: {
    onoff: boolean;
    title: any;
    text: any;
    type: any;
  };
  alertModal2: {
    onoff: boolean;
    title: any;
    text: any;
    btn1Text: string;
    btn2Text: string;
    noFunc?: any;
    okFunc: any;
  };
  alertModifyModal: {
    onoff: boolean;
    title: any;
    text: any;
    btn1Text: string;
    btn2Text: string;
    okFunc: any;
  };
  heading: {
    title: string;
    type: string | number;
  };
  socialLogin: {
    type: number;
    id: string;
  };
  term: { termidx: number; agree: number }[];
  studioTitle: string;
  backPath: string;
  idfindEmail: string;
  pwfindUsridx: number;
  unityUserimg: string | null | undefined;
  dormanModel: {
    onoff: boolean;
    last: string;
    dorman: string;
    usridx : number
  };
  terminationModel: boolean;
  programReload: boolean;
};

const initialState: Type = {
  serverip: "192.168.0.16",
  serverport: "35475",
  userInfo: {
    checkuser: null,
  },
  alertModal: {
    onoff: false,
    title: null,
    text: null,
    type: null,
  },
  alertModal2: {
    onoff: false,
    title: null,
    text: null,
    btn1Text: "",
    btn2Text: "",
    okFunc: null,
    noFunc: null,
  },
  alertModifyModal: {
    onoff: false,
    title: "알림",
    text: "",
    btn1Text: "취소",
    btn2Text: "확인",
    okFunc: null,
  },
  heading: {
    title: "",
    type: "",
  },
  socialLogin: {
    type: 0,
    id: "",
  },
  term: [],
  studioTitle: "",
  backPath: "",
  idfindEmail: "",
  pwfindUsridx: 0,
  unityUserimg: null,
  dormanModel: {
    onoff: false,
    last: "",
    dorman: "",
    usridx : 0
  },
  terminationModel: false,
  programReload: false,
};

const common = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_ALERT_MODAL":
      return updateObject({ ...state }, { alertModal: action.param });

    case "SET_ALERT2_MODAL":
      return updateObject({ ...state }, { alertModal2: action.param });

    case "SET_ALERT_MODIFY_MODAL":
      return updateObject({ ...state }, { alertModifyModal: action.param });

    case "SET_HEADING_DATA":
      return updateObject({ ...state }, { heading: action.param });

    case "SET_USER_INFO":
      return updateObject({ ...state }, { userInfo: action.param });

    case "SET_SOCAl_LOGIN":
      return updateObject({ ...state }, { socialLogin: action.param });

    case "SET_TERM_DATA":
      return updateObject({ ...state }, { term: action.param });

    case "SET_STUDIO_TITLE":
      return updateObject({ ...state }, { studioTitle: action.param });

    case "SET_BACK_PATH":
      return updateObject({ ...state }, { backPath: action.param });

    case "SET_IDFIND_EMAIL":
      return updateObject({ ...state }, { idfindEmail: action.param });

    case "SET_PWFIND_USRIDX":
      return updateObject({ ...state }, { pwfindUsridx: action.param });

    case "SET_UNITY_USERIMG":
      return updateObject({ ...state }, { unityUserimg: action.param });

    case "SET_DORMAN_MODAL":
      return updateObject({ ...state }, { dormanModel: action.param });

    case "SET_DORMAN_TERMINATION_MODAL":
      return updateObject({ ...state }, { terminationModel: action.param });

    case "SET_PROGRAM_RELOAD":
      return updateObject({ ...state }, { programReload: action.param });

    default:
      return state;
  }
};

export default common;
