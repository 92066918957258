import { useState, useEffect } from "react";
import { RootState } from "modules";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { cAPIMessagePack } from "utils/cAPI";
import { useTranslation } from "react-i18next";
import ProgramEndModal from "components/modal/programEndModal/ProgramEndModal";
import AnalysisBtn from "components/butttons/analysisBtn/AnalysisBtn";
import * as S from "./SideNav.Styled";
import { ReactComponent as IconLogout } from "assets/svg/ic_log_out_lined.svg";
import { ReactComponent as IconSystemShut2Lined } from "assets/svg/ic_system_shut_2_lined.svg";
import { ReactComponent as IconconfigurationSettings } from "assets/svg/configuration_settings_icon.svg";

import { set_header_info, set_token, set_user_index } from "modules/local";

import { useLogout } from "apis/loginApi";

type Props = {
  open: boolean;
  onClickClose: () => void;
};

const SideNav = ({ open = false, onClickClose }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const store = useSelector((state: RootState) => state);
  const usridx = store.local.usridx;
  const dispatch = useDispatch();
  const [programEnd, setProgramEnd] = useState(false);

  //api
  const logoutParam = {
    usridx: usridx,
    logtype: "logout",
  };
  const useLogoutApi = useLogout(logoutParam);

  useEffect(() => {
    const { data, isSuccess, remove } = useLogoutApi;
    if (isSuccess) {
      onClickClose();
      if (data?.result === "success") {
        dispatch(set_user_index(0));
        dispatch(set_header_info(null));
        dispatch(set_token(null));
        navigate("/login");
      }
      remove();
    }
  }, [useLogoutApi.data, useLogoutApi.isSuccess]);

  const onClickLogout = () => {
    useLogoutApi.refetch();
  };

  return (
    <>
      {open && <p className={`dimmed`} onClick={onClickClose}></p>}
      <S.SideNav open={open}>
        <S.Close onClick={onClickClose}>{t("Kiosk_Side_Close_Button")}</S.Close>
        <S.BtnArea>
          {/* <S.Btn onClick={()=>{(history.push("/userInfo")); onClickClose();}}>
            <S.Text>정보보기</S.Text>
            <IconChevronRight />
          </S.Btn> */}
          <S.Btn
            onClick={() => {
              cAPIMessagePack(`exbodykiosk:?type=option`);
            }}
          >
            <S.Text>{t("Kiosk_Side_Setting_Button")}</S.Text>
            <IconconfigurationSettings />
          </S.Btn>
          <S.Btn onClick={onClickLogout}>
            <S.Text>{t("Main_Btn_2")}</S.Text>
            <IconLogout />
          </S.Btn>
          <S.Btn onClick={() => setProgramEnd(true)}>
            <S.Text>{t("Kiosk_Login_Exit_BTN")}</S.Text>
            <IconSystemShut2Lined />
          </S.Btn>
        </S.BtnArea>
        <AnalysisBtn />
        <S.Info>
          <S.Center>{t("Kiosk_Side_SVCCenter_Text")}</S.Center>
          <S.Tell>{t("Kiosk_Side_SVCCenter_Number")}</S.Tell>
          <S.Terms>
            <Link to={"/serviceTerms"}>{t("Kiosk_Side_Terms_Text")}</Link>
          </S.Terms>
          <S.Copy>
            Copyright 2023.
            <br />
            exbody all rights reserved.
          </S.Copy>
        </S.Info>
      </S.SideNav>
      <ProgramEndModal
        onoff={programEnd}
        onCancel={() => setProgramEnd(false)}
      />
    </>
  );
};

export default SideNav;
