import "./Symbol.scss";
import { ReactComponent as KEMAIcon } from "assets/svg/play_circle_filled_24px.svg";
import { useTranslation } from "react-i18next";

const SchoolSymbol = () => {
  const { t } = useTranslation();
  return (
    <div className="symbol schoolSymbol caption3_medium ">
      <KEMAIcon />

      {t("Category_Name_10")}
    </div>
  );
};

export default SchoolSymbol;
