import "./Symbol.scss";
import { ReactComponent as KEMAIcon } from "assets/svg/play_circle_filled_24px.svg";
import { useTranslation } from "react-i18next";

const VideoSymbol = () => {
  const { t } = useTranslation();
  return (
    <p className="symbol videoSymbol caption3_medium ">
      <KEMAIcon />
      {t("Studio_Library_Group_Type_01")}
    </p>
  );
};

export default VideoSymbol;
