import styled from "styled-components";
import { font } from "assets/styled/fonts";
import { root } from "assets/styled/colors";

export const Home = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HomeContainer = styled.div`
  width: 1000px;
  height: 660px;
`;

export const Logo = styled.p`
  text-align: center;
`;

export const Text = styled.p`
  text-align: center;
  ${font.h1_regular};
  color: ${root.white};
  margin: 24px 0 62px;
`;

export const Btns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;

export const Button = styled.div`
  width: 480px;
  height: 480px;
  display: flex;
  justify-content: center;
  border-radius: 24px;
  overflow: hidden;
  a {
  }
`;

export const Image = styled.img`
  width: 480px;
  height: 316px;
  display: block;
`;

export const TextArea = styled.div`
  background-color: ${root.white};
  height: 164px;
`;

export const BtnTitle = styled.p`
  fontfamily: " Noto Sans KR, sansSerif";
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: ${root.black};
  padding: 24px 0 16px;
`;

export const BtnInfo = styled.p`
  ${font.h4_regular};
  color: ${root.black};
  text-align: center;
`;
