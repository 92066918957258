export const month : Array<any> = [ 
    { key: "month1", text : 'MembershipPage_DropDownBtn_1', value : '01' },
    { key: "month2", text : 'MembershipPage_DropDownBtn_2', value : '02'  },
    { key: "month3", text : 'MembershipPage_DropDownBtn_3', value : '03'  },
    { key: "month4", text : 'MembershipPage_DropDownBtn_4', value : '04'  },
    { key: "month5", text : 'MembershipPage_DropDownBtn_5', value : '05'  },
    { key: "month6", text : 'MembershipPage_DropDownBtn_6', value : '06'  },
    { key: "month7", text : 'MembershipPage_DropDownBtn_7', value : '07'  },
    { key: "month8", text : 'MembershipPage_DropDownBtn_8', value : '08'  },
    { key: "month9", text : 'MembershipPage_DropDownBtn_9', value : '09'  },
    { key: "month10", text : 'MembershipPage_DropDownBtn_10', value : '10'  },
    { key: "month11", text : 'MembershipPage_DropDownBtn_11' , value : '11' },
    { key: "month12", text : 'MembershipPage_DropDownBtn_12' , value : '12' },
]


export const gender : Array<any> = [ 
    { key: "man", text : 'MembershipPage_DropDownBtn_13', value : 1},
    { key: "female", text : 'MembershipPage_DropDownBtn_14', value : 2 },
    { key: "no", text : 'MembershipPage_DropDownBtn_15', value : 3 }
]

export const cate : Array<any> = [ 
    { key: "email", text : 'MembershipPage_InputTitle_1' },
    { key: "password", text : 'MembershipPage_InputTitle_2' },
    { key: "rePassword", text : 'MembershipPage_InputTitle_3' },
    { key: "name", text : 'MembershipPage_InputTitle_4' },
    { key: "gender", text : 'MembershipPage_InputTitle_6' },
    { key: "nickname", text : 'MembershipPage_InputTitle_7' },
    { key: "year", text : 'MembershipPage_InputTitle_5' },
    { key: "month", text : 'MembershipPage_InputTitle_5' },
    { key: "day", text : 'MembershipPage_InputTitle_5' }
]

export const trainerstate : Array<any> = [ 
    { text : '최초 등록', value : 0},
    { text : '심사중', value : 1},
    {  text : '심사 실패', value : 2 , color : "#ed3137"},
    {  text : '등록 성공', value : 3 , color:"#34c5ff"}
]