import { Link } from "react-router-dom";
import "./ImgLink.scss";

const ImgLink = ({ ...props }) => {
  const { src, to } = props;

  return (
    <p className={`imgLink`}>
      {to ? (
        <Link to={to}>
          {!src ? (
            <span className={`no_data`}></span>
          ) : (
            <img src={src} alt="imageLink" />
          )}
        </Link>
      ) : (
        <a>
          {!src ? (
            <span className={`no_data`}></span>
          ) : (
            <img src={src} alt="imageLink" />
          )}
        </a>
      )}
    </p>
  );
};

export default ImgLink;
