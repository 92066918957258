import "./InputModal.scss";
import { ReactComponent as ErrorIcon } from "assets/svg/ic_warning_circle_lined.svg";
import RecatangleTextBtn from "components/butttons/recatangleTextBtn/RecatangleTextBtn";
import { t } from "i18next";

type Props = {
  open: boolean;
  title: string;
  desc: string;
  onSubmit: () => void;
  placeholder?: string;
  onCancle?: () => void;
  state?: string;
  msg?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

const InputModal = ({
  open = false,
  desc,
  title = "",
  placeholder,
  onSubmit,
  onCancle,
  state,
  msg,
  onChange,
  value,
}: Props) => {
  const onClickCancle = () => {
    onCancle && onCancle();
  };

  const onClickOk = () => {
    onSubmit();
  };

  return (
    <>
      {open ? <p className={`dimmed`} onClick={onClickCancle}></p> : <></>}
      <div className={open ? `inputModal` : `inputModal off`}>
        <p className={`inputModalTitle body_medium`}>{t(title)}</p>
        <p className={`inputModalDesc caption1_regular`}>{desc}</p>
        <p className={`changeArea ${state}`}>
          <input
            title={`title`}
            className={`caption1_regular`}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
          />
        </p>
        <p className={`errormsg caption2_light`}>
          {state === "error" && (
            <>
              <ErrorIcon /> {msg}
            </>
          )}
        </p>
        <p className={`inputModalBtns`}>
          <RecatangleTextBtn
            sizeClass="normal"
            colorClass="line"
            text={t("Kiosk_Common_Btn_Cancel")}
            onClick={onClickCancle}
          />
          <RecatangleTextBtn
            sizeClass="normal"
            text={t("Common_Popup_Yes_Button")}
            onClick={onClickOk}
          />
        </p>
      </div>
    </>
  );
};

export default InputModal;
