import styled from "styled-components";
import { font } from "assets/styled/fonts";
import { root } from "assets/styled/colors";

export const DormantAccountModal = styled.div``;

export const Modal = styled.div`
  position: fixed;
  top: 24px;
  left: 50%;
  opacity: 1;
  transform: translateX(-50%);
  display: inline-block;
  width: 654px;
  border-radius: 8px;
  border: solid 2px var(--dark-opacity-80);
  background-color: var(--black);
  padding: 30px 40px;
  text-align: center;
  animation-duration: 0.4s;
  animation-name: open;
  z-index: 100;
`;

export const Title = styled.p`
  ${font.body_medium};
  color: ${root.white};
  margin-bottom: 24px;
`;

export const Text = styled.p`
  ${font.caption1_regular};
  color: ${root.darkgrey60};
`;

export const Box = styled.div`
  padding: 8px 12px;
  border-radius: 4px;
  border: solid 1px ${root.darkopacity80};
  background-color: ${root.darkopacity10};
  width: 240px;
  margin: 16px auto;
  ${Text} {
    margin: 8px 0;
    color: ${root.white};
    span {
      //color: ${root.darkgrey60};
      margin-left: 4px;
    }
  }
`;

export const Btns = styled.p`
  display: flex;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
  button {
    width: 140px;
  }
`;
