import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
//style
import * as S from "./ExerciseNav.Styled";

//store
import { set_ex_page, set_scroll_y } from "modules/local";

//img
import BackImg from "assets/img/nav_back_img.png";

type Props = {
  value: number;
  onClick: React.Dispatch<React.SetStateAction<number>>;
  navList: Object[] | null;
};

const ExerciseNav = ({ value, onClick, navList }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // onClick(newValue);
    dispatch(set_ex_page(1));
    dispatch(set_scroll_y(0));
    navigate(`?cate=${newValue}`);
  };

  return (
    <S.ExerciseNavBox>
      <S.ExerciseNav>
        <Box
          bgcolor="white"
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            height: "100%",
            boxShadow: 3,
            background: `rgba(0,0,0,0) url(${BackImg}) no-repeat center`,
            backgroundSize: "100% 100%",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            TabIndicatorProps={{
              style: {
                background: "rgba(255,255,255,0)",
              },
            }}
          >
            {navList?.map((data, index) => (
              <Tab
                key={`exNave_${index}`}
                label={t(Object.entries(data)[0][1])}
                sx={{
                  opacity: "0.5",
                  fontFamily: "Noto Sans KR, sansSerif",
                  fontSize: "24px",
                  fontWeight: "bold",
                  lineHeight: "1.25",
                  letterSpacing: " -0.3px",
                  color: "white",
                  maxWidth: "auto",
                  height: "100px",
                }}
              />
            ))}
          </Tabs>
        </Box>
      </S.ExerciseNav>
    </S.ExerciseNavBox>
  );
};

export default ExerciseNav;
