import "./AnalysisBtn.scss";

import analysisProgramIcon from "assets/img/analysisProgramIcon.png";
import { cAPIMessagePack } from "utils/cAPI";

const AnalysisBtn = () => {
  const toAnalysisBtn = () => {
    cAPIMessagePack("Analysis");
  };

  return (
    <p className={`analysisBtn`} onClick={toAnalysisBtn}>
      <img src={analysisProgramIcon} alt="AnalysisIcon" />
      <span className={`body_medium`}>exbody Analysis</span>
    </p>
  );
};

export default AnalysisBtn;
