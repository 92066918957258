import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "modules";
import InfiniteScroll from "react-infinite-scroll-component";
import { usePrevState } from "hooks/usePrevState";
import "./ExerciseViewAll.scss";
import { searchGet } from "utils/commons";
import { useGetexercise, useExerciseNav } from "apis/exerciseApi";
import { set_ex_page } from "modules/local";
import SquareCard from "components/cards/squareCard/SquareCard";
import ExerciseNav from "components/navigation/exerciseNav/ExerciseNav";
import BackLayout from "components/backLayout/BackLayout";
import NoData from "components/infos/noData/NoData";

const ExerciseViewAll = () => {
  const dispatch = useDispatch();
  const store = useSelector((state: RootState) => state);
  const usridx = store.local.usridx;
  const studioPrograms = store.local.studioPrograms || [1];
  const exPage = store.local.exPage;
  const companyIdx = store.local.companyIdx || 0;
  const scrollY = store.local.scrollY;
  const [pageNavState, setPageNaveState] = useState({
    page: 20,
    dateLength: 25,
  });
  const [clickPage, setClickPage] = useState(1);
  const [commonCodeArr, setCommonCodeArr] = useState<any[] | null>(null);
  const [dataArr, setDataArr] = useState<ExTypeRectangleType[] | null>(null);
  const [navList, setNavList] = useState<Object[] | null>([]);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const prevValu = usePrevState(value);
  const categorylist = store.local.categorylist || [];

  //api
  const listParam = {
    usridx: usridx,
    libraryGroup: studioPrograms,
    categorylist: categorylist,
  };
  const useExerciseNavApi = useExerciseNav(listParam);

  const selectCategory =
    navList && navList[value] && Object.entries(navList[value])[0][0];

  const param = {
    page: clickPage,
    category: selectCategory,
    usridx: usridx,
    libraryGroup:
      selectCategory === "ms"
        ? [9]
        : selectCategory === "spoex"
        ? [5]
        : studioPrograms,
    companyIdx: companyIdx,
  };
  const useGetexerciseApi = useGetexercise(param);

  //search 값이 변경될 때마다 page관련 state값 변경하기
  useEffect(() => {
    useExerciseNavApi.refetch();
  }, [window.location.pathname]);

  useEffect(() => {
    return () => {
      setDataArr(null);
      setValue(0);
    };
  }, []);

  useEffect(() => {
    const search = searchGet("cate");
    const index = search ? Number(search) : 0;
    setValue(index);
  }, [window.location.search]);

  useEffect(() => {
    if (clickPage > 0 && navList && navList[value]) {
      console.log(navList[4]);

      useGetexerciseApi.refetch();
    }
  }, [clickPage, navList && navList[value]]);

  useEffect(() => {
    setClickPage(1);
    setDataArr(null);
  }, [value]);

  useEffect(() => {
    const { data, isSuccess } = useExerciseNavApi;
    if (isSuccess && data?.result === "success") {
      setNavList(data?.navigationList);
    }
  }, [useExerciseNavApi.data, useExerciseNavApi.isSuccess]);

  useEffect(() => {
    const { data, isSuccess, remove } = useGetexerciseApi;
    if (
      isSuccess &&
      data?.programData?.length > 0 &&
      ((dataArr &&
        dataArr?.length < pageNavState?.dateLength &&
        prevValu === value) ||
        prevValu !== value ||
        dataArr === null)
    ) {
      setPageNaveState({
        ...pageNavState,
        dateLength: data?.totalProgramCount,
      });
      const datas =
        dataArr && clickPage > 1
          ? [...dataArr, ...data?.programData]
          : data?.programData;
      setDataArr(datas);
      setCommonCodeArr(data?.commonCode);

      if (exPage > 0) {
        if (exPage && (clickPage === exPage || clickPage > exPage)) {
          dispatch(set_ex_page(null));
          if (scrollY > 0) {
            setTimeout(() => {
              window.scrollTo(0, scrollY);
            }, 100);
          }
          setLoading(false);
        } else {
          setClickPage(clickPage + 1);
        }
      } else {
        setLoading(false);
      }

      remove();
    }
  }, [useGetexerciseApi.data, useGetexerciseApi.isSuccess]);

  const fetchMoreData = () => {
    if (
      dataArr &&
      dataArr?.length < pageNavState.dateLength &&
      !useGetexerciseApi.isLoading &&
      !loading
    ) {
      setClickPage(clickPage + 1);
    }
  };

  return (
    <BackLayout>
      <div className={`viewAll`}>
        <ExerciseNav
          value={value}
          onClick={setValue}
          navList={navList && navList}
        />
        {/* <p className={`h1_bold title`}>{t(navList[value]?.text)}</p> */}
        <div className={`dataArea`}>
          {dataArr && dataArr?.length > 0 ? (
            <InfiniteScroll
              dataLength={dataArr?.length} //This is important field to render the next data
              next={fetchMoreData}
              hasMore={true}
              loader={<></>}
            >
              {dataArr?.map((data: ExTypeRectangleType) => (
                <SquareCard
                  data={data}
                  commonCode={commonCodeArr}
                  clickPage={clickPage}
                  key={data.idx}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <div className="nodata">
              <NoData text="ExercisePage_Nocontent_Title" />
            </div>
          )}
        </div>
      </div>
    </BackLayout>
  );
};

export default ExerciseViewAll;
