import { useTranslation } from "react-i18next";
import { cAPIMessagePack } from "utils/cAPI";
import RecatangleTextBtn from "components/butttons/recatangleTextBtn/RecatangleTextBtn";

interface Props {
  onoff: boolean;
  onCancel: () => void;
}
const ProgramEndModal = ({ onoff = false, onCancel }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {onoff && <p className={`dimmed`} onClick={onCancel}></p>}
      <div className={onoff ? `alertModal2 end` : `alertModal2 end off `}>
        <div className={`caption1_regular text`}>
          {/* {`${seconds}초 후 프로그램이 종료됩니다.`} */}
          {/* {stringFormat(t("Kiosk_Login_Exit_Pop"), seconds)} */}
          {t("Kiosk_Login_Exit_Pop")}
        </div>
        <div className={`btns`}>
          <p className={`btn1`}>
            <RecatangleTextBtn
              text={t("Kiosk_Common_Btn_Cancel")}
              sizeClass="normal"
              colorClass="gray"
              onClick={onCancel}
            />
          </p>
          <p className={`btn2`}>
            <RecatangleTextBtn
              text={t("Common_Popup_Yes_Button")}
              sizeClass="normal"
              onClick={() => {
                cAPIMessagePack(`exbodyKiosk:?type=-1`);
                onCancel();
              }}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default ProgramEndModal;
