import * as S from "./DormantAccountModal.Styled";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { colorTagChange, stringFormat, handleYYYYMMDD } from "utils/commons";
import { set_dorman_model, set_dorman_termination_model } from "modules/common";
import parser from "html-react-parser";
import RecatangleTextBtn from "components/butttons/recatangleTextBtn/RecatangleTextBtn";
import { RootState } from "modules";

const DormantAccountModal = () => {
  const { t } = useTranslation();
  const store = useSelector((state: RootState) => state);
  const dormanModel = store.common.dormanModel;
  const dispatch = useDispatch();

  const closeFunc = () => {
    dispatch(set_dorman_model({ ...dormanModel, onoff: false }));
  };

  const rightFunc = () => {
    closeFunc();
    dispatch(set_dorman_termination_model(true));
  };

  return (
    <>
      {dormanModel.onoff ? (
        <p className={`dimmed`} onClick={closeFunc}></p>
      ) : (
        <></>
      )}
      {dormanModel.onoff && (
        <S.Modal>
          <S.Title>{t("Dormant_Account_Pop_Title_Text")}</S.Title>
          <S.Text>{t("Dormant_Account_Pop_Description_1")}</S.Text>
          <S.Box>
            <S.Text>
              {parser(
                stringFormat(
                  t("Dormant_Account_Pop_Last_Login_Text"),
                  `<span>${handleYYYYMMDD(dormanModel?.last)}</span>`
                )
              )}
            </S.Text>
            <S.Text>
              {parser(
                stringFormat(
                  t("Dormant_Account_Pop_Dormant_Text"),
                  `<span>${handleYYYYMMDD(dormanModel?.dorman)}</span>`
                )
              )}
            </S.Text>
          </S.Box>
          <S.Text>
            {parser(colorTagChange(t("Dormant_Account_Pop_Description_2")))}
          </S.Text>
          <S.Btns>
            <RecatangleTextBtn
              text={t(`Dormant_Account_Pop_Cancel_Button`)}
              sizeClass="normal"
              colorClass="gray"
              onClick={closeFunc}
            />
            <RecatangleTextBtn
              text={t(`Dormant_Account_Pop_Dormant_Button`)}
              sizeClass="normal"
              onClick={rightFunc}
            />
          </S.Btns>
        </S.Modal>
      )}
    </>
  );
};

export default DormantAccountModal;
