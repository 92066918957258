import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import "./assets/lang/i18n";
import { QueryClientProvider, QueryClient } from "react-query";
import { CookiesProvider } from "react-cookie";
import { store } from "./modules";

import "./assets/scss/index.scss";
import "./assets/scss/font.scss";
import "./assets/scss/color.scss";

// // // 배포 레벨에서는 리덕스 발동시 찍히는 logger를 사용하지 않습니다.
// const enhancer =
//   process.env.NODE_ENV === "production"
//     ? compose(applyMiddleware())
//     : composeWithDevTools(applyMiddleware(logger));

const queryClient = new QueryClient();
const persistor = persistStore(store);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root")
);
