import styled from "styled-components";
import { font } from "assets/styled/fonts";
import { root } from "assets/styled/colors";

interface BackLayoutProps {
  maxWidth?: string;
  bgColor?: string;
}

export const BackLayout = styled.div<BackLayoutProps>`
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) =>
    props.bgColor ? root.black2 : "#111"}; //root.black
`;

export const Back = styled.div`
  border: solid 1px var(--dark-grey-30);
  border-left: none;
  position: fixed;
  top: 100px;
  left: -8px;
  z-index: 100;
  border-radius: 8px;
  text-align: center;
  background-color: #111;
  height: 156px;
  cursor: pointer;
  svg {
    display: block;
    margin: 40px auto 24px;
  }
`;

export const Text = styled.p`
  ${font.h4_regular};
  color: ${root.darkgrey60};
  width: 150px;
  height: 22px;
  text-align: center;
`;

export const Container = styled.div<BackLayoutProps>`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "1920px")};
  margin: 0 auto;
  background-color: ${(props) =>
    props.bgColor ? root.black2 : "#111"}; //root.black
`;
