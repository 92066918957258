import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

//style
import { Link } from "react-router-dom";
import * as S from "./Home.Styled";

//store
import { set_ex_page, set_scroll_y } from "modules/local";

//svg
import { ReactComponent as LogoMetagymLand } from "assets/logo/logo_metagym_land_g.svg";
import homeExercise from "assets/img/home_exercise.png";
import homeStudio from "assets/img/home_studio.png";

const btns = [
  {
    to: "/exercise",
    title: "MainPage_Menu_Btn_Exercise",
    info: "Kiosk_Main_Exercise_BTN_Info",
    img: homeExercise,
  },
  {
    to: "/studio",
    title: "MainPage_Menu_Btn_Studio",
    info: "Kiosk_Main_Studio_BTN_Info",
    img: homeStudio,
  },
];

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set_ex_page(1));
    dispatch(set_scroll_y(0));
  }, [window.location.pathname]);

  return (
    <S.Home>
      <S.HomeContainer>
        <S.Logo>
          <LogoMetagymLand />
        </S.Logo>
        <S.Text>{t("Kiosk_Main_Title")}</S.Text>
        <S.Btns>
          {btns.map((data) => (
            <S.Button key={data.to}>
              <Link to={data.to}>
                <S.Image src={data.img} alt="" />
                <S.TextArea>
                  <S.BtnTitle>{t(data.title)}</S.BtnTitle>
                  <S.BtnInfo>{t(data.info)}</S.BtnInfo>
                </S.TextArea>
              </Link>
            </S.Button>
          ))}
        </S.Btns>
      </S.HomeContainer>
    </S.Home>
  );
};

export default Home;
