import React from "react";
import "./StudioProduce.scss";
import { useSelector } from "react-redux";

import { cAPIMessagePack } from "utils/cAPI";
import { RootState } from "modules";
import { useTranslation } from "react-i18next";
import BackLayout from "components/backLayout/BackLayout";

const StudioProduce = () => {
  const { t } = useTranslation();
  const store = useSelector((state: RootState) => state);
  const usridx = store.local.usridx;
  const studioPrograms = store.local.studioPrograms || [1];

  const programBtns = [
    {
      type: 1,
      title: "Studio_Version_Name_1",
      desc: "Studio_Version_Info_1",
    },
    {
      type: 5,
      title: "Studio_Library_Group_Name_01",
      desc: "Studio_Version_Info_2",
    },
    {
      type: 6,
      title: "Category_Name_9",
      desc: "Studio_Version_Info_2",
    },
    {
      type: 9,
      title: "Category_Name_10",
      desc: "Studio_Version_Info_3",
    },
  ];

  return (
    <BackLayout maxWidth="100%">
      <div className={`studioProduce`}>
        <div className={`produceContainer`}>
          <p className={`title h2_bold`}>{t("Studio_Version_Select_Text")}</p>
          <ul className={`btns`}>
            {programBtns.map((program, index) => (
              <React.Fragment key={program.title + index}>
                {studioPrograms.includes(program.type) && (
                  <li
                    className={`btn`}
                    onClick={() => {
                      cAPIMessagePack(
                        `exbodyKiosk:?type=3&usridx=${usridx}&datatype=${program.type}`
                      );
                    }}
                  >
                    <p className={`btnTitle d1_bold btnDesc${program.type}`}>
                      {t(program.title)}
                    </p>
                    <pre className={`btnDesc h3_medium`}>{t(program.desc)}</pre>
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
    </BackLayout>
  );
};

export default StudioProduce;
