import "./Symbol.scss";
import { ReactComponent as KEMAIcon } from "assets/svg/play_circle_filled_24px.svg";
import { useTranslation } from "react-i18next";

const KEMASymbol = () => {
  const { t } = useTranslation();
  return (
    <p className="symbol kemaSymbol caption3_medium ">
      <KEMAIcon />

      {t("Studio_Library_Group_Type_02")}
    </p>
  );
};

export default KEMASymbol;
