import styled from "styled-components";
import { root } from "assets/styled/colors";
import { font } from "assets/styled/fonts";

interface StylesProps {
  hover?: boolean;
}

export const Tooltip = styled.span`
  visibility: ${(props: StylesProps) => (props.hover ? "visible" : "hidden")};
  opacity: ${(props: StylesProps) => (props.hover ? "1" : "0")};
  position: absolute;
  top: ${(props: StylesProps) => (props.hover ? "-40px" : "-35px")};
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0 12px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 var(--dark-opacity-60);
  background-color: var(--dark-grey-80);
  z-index: 10;
  ${font.caption3_regular};
  color: ${root.darkgrey10};
  text-align: center;
  white-space: pre;
  transition: all 0.3s;
  height: 28px;
  line-height: 28px;
  &:after {
    position: absolute;
    top: 26px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
    display: inline-block;
    content: "";
    border-top: 6px solid var(--dark-grey-80);
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
`;
