import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "modules";
import { useTranslation } from "react-i18next";
import parser from "html-react-parser";
import "./AlertModal.scss";
import RecatangleTextBtn from "components/butttons/recatangleTextBtn/RecatangleTextBtn";
import { set_alert_modal } from "modules/common";

const AlertModal = () => {
  const { t } = useTranslation();
  const store = useSelector((state: RootState) => state);
  const alertModalData = store.common.alertModal;
  const dispath = useDispatch();
  const modalRef = useRef<HTMLInputElement>(null);

  const body = document.querySelector("body") as HTMLElement;
  const [scrollPositions, setScrollPositions] = useState(window.scrollY);

  const alertModalFunc = () => {
    dispath(
      set_alert_modal({
        onoff: false,
        title: null,
        text: null,
      })
    );
  };

  // 모달 떴을 때 focus 이동
  useLayoutEffect(() => {
    if (modalRef.current !== null) {
      modalRef.current.focus();
    }
  });

  // 모달 위치 설정
  window.addEventListener("scroll", () => {
    if (window.scrollY !== 0) {
      setScrollPositions(window.scrollY);
    }
  });

  useEffect(() => {
    if (alertModalData?.onoff) {
      body.style.top = `-${scrollPositions}px`;
      body.style.overflow = "hidden";
      body.style.position = "fixed";
      body.style.left = "0";
      body.style.right = "0";
    } else {
      // setScrollPositions(window.scrollY);
      body.style.removeProperty("overflow");
      body.style.removeProperty("position");
      body.style.removeProperty("top");
      body.style.removeProperty("left");
      body.style.removeProperty("right");
      window.scrollTo(0, scrollPositions);
    }

    return () => {};
  }, [alertModalData?.onoff]);

  return (
    <>
      {alertModalData?.onoff ? (
        <p className={`dimmed`} onClick={alertModalFunc}></p>
      ) : (
        <></>
      )}
      <div
        className={
          alertModalData?.onoff
            ? `alertModal ${alertModalData?.type}`
            : `alertModal off ${alertModalData?.type}`
        }
      >
        <input style={{ height: "0", display: "block" }} ref={modalRef} />
        {alertModalData?.title && (
          <p className={`body_medium title`}>{alertModalData?.title}</p>
        )}
        <div className={`caption1_regular text`}>
          {parser(`<p>${alertModalData?.text}</p>`)}
        </div>
        <p className={`btn`}>
          <RecatangleTextBtn
            text={t("Common_Popup_Yes_Button")}
            sizeClass="normal"
            onClick={alertModalFunc}
          />
        </p>
      </div>
    </>
  );
};

export default AlertModal;
