import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./modules";
import { useLogout } from "./apis/loginApi";
import { set_header_info, set_token, set_user_index } from "./modules/local";
import Header from "./components/header/Header";
import Login from "./view/login/Login";
import Home from "./view/home/Home";
import ExerciseViewAll from "./view/exercise/exerciseViewAll/ExerciseViewAll";
import ExerciseDetail from "./view/exercise/exerciseDetail/ExerciseDetail";
import Studio from "./view/studio/Studio";
import FooterPrivacyPolicyTerms from "./components/terms/FooterPrivacyPolicyTerms";
import VideoEXStart from "./view/exercise/videoEx/VideoEXStart";
import StudioProduce from "./view/studio/StudioProduce";

const RouterComponent = () => {
  const { pathname } = useLocation();
  let interval: any = null;

  const dispatch = useDispatch();
  const store = useSelector((state: RootState) => state);
  const { usridx, isUnity } = store.local;
  const [seconds, setSeconds] = useState(10);
  const [onoff, setOnoff] = useState(false);
  const navigate = useNavigate();
  const logoutParam = {
    usridx: usridx,
    logtype: "logout",
  };
  const useLogoutApi = useLogout(logoutParam);

  useEffect(() => {
    return () => clearTimeout(interval);
  }, []);

  useEffect(() => {
    let countdown: NodeJS.Timeout | undefined = undefined;

    if (onoff) {
      countdown = setInterval(() => {
        if (seconds > 1) {
          setSeconds(seconds - 1);
        }
        if (seconds === 1) {
          useLogoutApi.refetch();
          setOnoff(false);
        }
      }, 1000);
    } else {
      clearInterval(countdown);
      setSeconds(10);
    }
    return () => clearInterval(countdown);
  }, [onoff, seconds]);

  useEffect(() => {
    if (!onoff) {
      if (usridx > 0 && !isUnity) {
        // windowMousemoveFunc();
      } else {
        clearTimeout(interval);
      }
    }
  }, [usridx, onoff, isUnity]);

  useEffect(() => {
    if (pathname.includes("userInfo") && usridx > 0) {
      navigate("/");
    }

    const body = document.querySelector("body");
    if (pathname.includes("exercise")) {
      body?.classList.remove("scrollNone");
      body?.classList.add("scroll");
    } else {
      body?.classList.add("scroll");
      body?.classList.add("scrollNone");
    }
  }, [pathname, usridx]);

  useEffect(() => {
    const { data, isSuccess, remove } = useLogoutApi;
    if (isSuccess) {
      if (data?.result === "success") {
        dispatch(set_user_index(0));
        dispatch(set_header_info(null));
        dispatch(set_token(null));
        navigate("/login");
      }
      remove();
    }
  }, [useLogoutApi.data, useLogoutApi.isSuccess]);

  return (
    <>
      {!pathname.includes("videoEX") && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/exercise" element={<ExerciseViewAll />} />
        <Route path="/exercise/detail" element={<ExerciseDetail />} />
        <Route path="/studio" element={<Studio />} />
        <Route path="/serviceTerms" element={<FooterPrivacyPolicyTerms />} />
        <Route path="/studioProduce" element={<StudioProduce />} />
        <Route path="/videoEXStart" element={<VideoEXStart />} />
      </Routes>
    </>
  );
};

export default RouterComponent;
