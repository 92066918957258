import "./CircleProfileIcon.scss";
import user_thumb_default from "assets/img/user_thumb_default.png";

const CircleProfileIcon = ({ src = user_thumb_default, alt = "" }) => {
  return (
    <p className={`circleProfileIcon`}>
      {src ? (
        <img src={src} alt={alt} />
      ) : (
        <img src={user_thumb_default} alt="img" />
      )}
    </p>
  );
};

export default CircleProfileIcon;
