import * as S from "./DormantTermination.Styled";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  set_dorman_termination_model,
  set_dorman_model,
  set_alert_modal,
} from "modules/common";
import { nameValFunc, emailValFunc } from "utils/validate";
import { month } from "datas/Join";
import { useUnlock } from "apis/loginApi";

import RecatangleTextBtn from "components/butttons/recatangleTextBtn/RecatangleTextBtn";
import RectangelTextField from "components/fields/rectangelTextField/RectangelTextField";
import RectangleSelect from "components/selects/rectangleSelect/RectangleSelect";

import { RootState } from "modules";

const DormantTermination = () => {
  const { t } = useTranslation();
  const store = useSelector((state: RootState) => state);
  const { terminationModel, dormanModel } = store.common;
  const dispatch = useDispatch();
  const defaultError = {
    messageType: "",
    errorMessage: <span></span>,
    confirmMessage: <span></span>,
  };
  const defaultSuccess = {
    messageType: "success",
    errorMessage: <span></span>,
    confirmMessage: <span></span>,
  };
  const defaultData = {
    usremail: "",
    usrname: "",
    year: "",
    month: { key: "", text: "", value: "" },
    day: "",
  };
  const [isJoin, setJoin] = useState(defaultData);
  const [valEmail, setValEmail] = useState(defaultError);
  const [valName, setValName] = useState(defaultError);
  const [valYear, setValYear] = useState(defaultError);
  const [valDay, setValDay] = useState(defaultError);
  const [isFocus, setFocus] = useState("");
  const [action, setAction] = useState(false);

  const param = {
    usridx: dormanModel.usridx,
    usrname: isJoin.usrname,
    usremail: isJoin.usremail,
    usrbirth: `${isJoin.year}-${isJoin.month.value}-${isJoin.day.padStart(
      2,
      "0"
    )}`,
  };
  const useUnlockApi = useUnlock(param);

  useEffect(() => {
    const { data, isSuccess } = useUnlockApi;
    if (isSuccess) {
      if (data?.result === "success") {
        let alertModal = {
          onoff: true,
          title: "성공!",
          text: t("Dormant_Account_Pop_Identity_Description_2"),
          type: "success",
        };

        dispatch(set_alert_modal(alertModal));
      } else {
        let alertModal = {
          onoff: true,
          title: t("Kiosk_Common_Failure_Text"),
          text: t("Dormant_Account_Pop_Identity_Description_3"),
          type: "err",
        };

        dispatch(set_alert_modal(alertModal));
      }
    }
  }, [useUnlockApi.data, useUnlockApi.isSuccess]);

  useEffect(() => {
    if (
      valEmail.messageType === "success" &&
      valName.messageType === "success" &&
      valYear.messageType === "success" &&
      valDay.messageType === "success"
    ) {
      setAction(true);
    } else {
      setAction(false);
    }
  }, [valEmail, valName, valYear, valDay]);

  const closeFunc = () => {
    dispatch(
      set_dorman_model({ onoff: false, last: "", dorman: "", usridx: 0 })
    );
    dispatch(set_dorman_termination_model(false));
    setJoin(defaultData);
    setAction(false);
    setValEmail(defaultError);
    setValName(defaultError);
    setValYear(defaultError);
    setValDay(defaultError);
  };

  const rightFunc = async () => {
    await useUnlockApi.refetch();
    closeFunc();
  };

  //focus out 유효정 체크
  const valEmailFunc = () => {
    const val = emailValFunc(isJoin.usremail);
    if (val) {
      setValEmail(defaultSuccess);
    } else if (isJoin.usremail.length === 0) {
      setValEmail({ ...valEmail, messageType: "" });
    } else {
      setValEmail({
        messageType: "error",
        errorMessage: <span>{t("MembershipPage_Email_Err_2")}</span>,
        confirmMessage: <span></span>,
      });
    }
  };

  const valNameFunc = () => {
    const val = nameValFunc(isJoin.usrname);
    if (val) {
      setValName(defaultSuccess);
    } else if (isJoin.usrname.length === 0) {
      setValName({ ...valName, messageType: "" });
    } else {
      setValName({
        messageType: "error",
        errorMessage: <span>{t("MembershipPage_Name_Err_1")}</span>,
        confirmMessage: <span></span>,
      });
    }
  };

  const valYearFunc = () => {
    if (Number(isJoin.year) < 1900 || Number(isJoin.year) > 9999) {
      setValYear({
        messageType: "error",
        errorMessage: <span>{t("Kiosk_Studio_Reconfirm_Text")}</span>,
        confirmMessage: <span></span>,
      });
    } else {
      setValYear(defaultSuccess);
    }
  };

  const valDayFunc = () => {
    if (Number(isJoin.day) < 1 || Number(isJoin.day) > 31) {
      setValDay({
        messageType: "error",
        errorMessage: <span>{t("Kiosk_Studio_Reconfirm_Text")}</span>,
        confirmMessage: <span></span>,
      });
    } else {
      setValDay({
        messageType: "success",
        errorMessage: <span></span>,
        confirmMessage: <span></span>,
      });
    }
  };

  const focusFunc = (cate: string) => {
    setFocus(cate);
  };

  //select값 변경
  const selectJoinFunc = (cate: string, data: any) => {
    setJoin({ ...isJoin, [cate]: data });
  };

  //input값 변경
  const onChangeJoinValue = (e: any) => {
    let { name, value } = e.target;

    if (name === "year" || name === "day") {
      value = value.replace(/[^0-9]/g, "");
    }

    if (name === "year" && Number(value) > 9999 && !isNaN(Number(value))) {
      return false;
    } else if (name === "day" && Number(value) > 31 && Number(value)) {
      return false;
    }

    if (name === "usremail") setValEmail(defaultError);
    setJoin({ ...isJoin, [name]: value });
  };

  return (
    <>
      {terminationModel ? (
        <p className={`dimmed`} onClick={closeFunc}></p>
      ) : (
        <></>
      )}
      {terminationModel && (
        <S.Modal>
          <S.Title>{t("Dormant_Account_Pop_Identity_Title_1")}</S.Title>
          <S.InputArea>
            <S.Label className={`caption1_medium label essential`}>
              {t("MembershipPage_InputTitle_1")}
            </S.Label>
            <RectangelTextField
              width="300px"
              placeholder={t("MembershipPage_Guide_1")}
              value={isJoin.usremail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeJoinValue(e);
              }}
              messageType={valEmail.messageType}
              errorMessage={valEmail.errorMessage}
              confirmMessage={valEmail.confirmMessage}
              blurEvent={valEmailFunc} //중복체크
              focus={isFocus === "usremail"}
              setFocus={focusFunc}
              cate="usremail"
            />
          </S.InputArea>
          <S.InputArea>
            <S.Label className={`caption1_medium label essential`}>
              {t("MembershipPage_InputTitle_4")}
            </S.Label>
            <RectangelTextField
              width="300px"
              placeholder={t("MembershipPage_Guide_4")}
              value={isJoin.usrname}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeJoinValue(e);
              }}
              messageType={valName.messageType}
              errorMessage={valName.errorMessage}
              confirmMessage={valName.confirmMessage}
              blurEvent={valNameFunc}
              focus={isFocus === "usrname"}
              setFocus={focusFunc}
              cate="usrname"
            />
          </S.InputArea>
          <S.InputArea>
            <S.Label className={`caption1_medium label essential`}>
              {t("MembershipPage_InputTitle_5")}
            </S.Label>
            <S.DayArea>
              <div className={`dayInput`}>
                <RectangelTextField
                  width="160px"
                  placeholder={t("MembershipPage_Guide_5")}
                  value={isJoin.year}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeJoinValue(e);
                  }}
                  maxLength="4"
                  focus={isFocus === "year"}
                  setFocus={focusFunc}
                  cate="year"
                  blurEvent={valYearFunc}
                  messageType={valYear.messageType}
                  errorMessage={valYear.errorMessage}
                  confirmMessage={valYear.confirmMessage}
                />
              </div>
              <div className={`monthSelect`}>
                <RectangleSelect
                  placeholder={t("MembershipPage_Guide_6")}
                  menus={month}
                  select={isJoin.month}
                  cate="month"
                  onClick={selectJoinFunc}
                />
              </div>
              <div className={`dayInput`}>
                <RectangelTextField
                  width="114px"
                  placeholder={t("MembershipPage_Guide_7")}
                  value={isJoin.day}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChangeJoinValue(e);
                  }}
                  maxLength="2"
                  focus={isFocus === "day"}
                  setFocus={focusFunc}
                  cate="day"
                  blurEvent={valDayFunc}
                  messageType={valDay.messageType}
                  errorMessage={valDay.errorMessage}
                  confirmMessage={valDay.confirmMessage}
                />
              </div>
            </S.DayArea>
          </S.InputArea>
          <S.Btns>
            <RecatangleTextBtn
              text={t(`Common_Popup_No_Button`)}
              sizeClass="normal"
              colorClass="gray"
              onClick={closeFunc}
            />
            <RecatangleTextBtn
              text={t(`Common_Popup_Yes_Button`)}
              sizeClass="normal"
              onClick={rightFunc}
              action={action}
            />
          </S.Btns>
        </S.Modal>
      )}
    </>
  );
};

export default DormantTermination;
