import api from "../utils/api";
import { useQuery } from "react-query";
import { URL } from "../datas/common";

//심사성공, 실패, 대기 등록
const evaluate = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/evaluate`, {
    ...param,
  });
  return data;
};

export const useEvaluate = (param: any) => {
  return useQuery(["evaluate", param], () => evaluate(param), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

//심사실패 수정사항 저장
const revision = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/revision`, {
    ...param,
  });
  return data;
};

export const useRevision = (param: any) => {
  return useQuery(["revision", param], () => revision(param), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

//심사대기, 실패 목록 불러오기
const studioEvaluatefind = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/evaluatefind`, {
    ...param,
  });
  return data;
};

export const useStudioEvaluatefind = (param: any) => {
  return useQuery(
    ["studioEvaluatefind", param],
    () => studioEvaluatefind(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

//업로드 목록 조회
const studioUploadstate = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/uploadstate`, {
    ...param,
  });
  return data;
};

export const useStudioUploadstate = (param: any) => {
  return useQuery(
    ["studioUploadstate", param],
    () => studioUploadstate(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

//서비스중인 프로그램 목록
const studioServicelist = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/servicelist`, {
    ...param,
  });
  return data;
};

export const useStudioServicelist = (param: any) => {
  return useQuery(
    ["studioServicelist", param],
    () => studioServicelist(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

//제작운동 ,업로드 프로그램 삭제
const studioDeleteprogram = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/deleteprogram`, {
    ...param,
  });
  return data;
};

export const useStudioDeleteprogram = (param: any) => {
  return useQuery(
    ["studioDeleteprogram", param],
    () => studioDeleteprogram(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

//트레이너 자격증 유무 확인
const studioChecktrainer = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/checktrainer`, {
    ...param,
  });
  return data;
};

export const useStudioChecktrainer = (param: any) => {
  return useQuery(
    ["studioChecktrainer", param],
    () => studioChecktrainer(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

//제작목록 조회
const studioProgramstate = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/programstate`, {
    ...param,
  });
  return data;
};

export const useStudioProgramstate = (param: any) => {
  return useQuery(
    ["studioProgramstate", param],
    () => studioProgramstate(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

//업로드 운동 프로그램 서비스중단
const studioDeleteuploadprogram = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/deleteuploadprogram`, {
    ...param,
  });
  return data;
};

export const useStudioDeleteuploadprogram = (param: any) => {
  return useQuery(
    ["studioDeleteuploadprogram", param],
    () => studioDeleteuploadprogram(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

//운동 프로그램 분석
const studioProgramanalyze = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/programanalyze`, {
    ...param,
  });
  return data;
};

export const useStudioProgramanalyze = (param: any) => {
  return useQuery(
    ["studioProgramanalyze", param],
    () => studioProgramanalyze(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};


//프로그램 이용 횟수 분석
const studioNumberanalyze = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/numberanalyze`, {
    ...param,
  });
  return data;
};

export const useStudioNumberanalyze = (param: any) => {
  return useQuery(
    ["studioNumberanalyze", param],
    () => studioNumberanalyze(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};


//사용자 분석
const studioUseranalyze = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/useranalyze`, {
    ...param,
  });
  return data;
};

export const useStudioUseranalyze = (param: any) => {
  return useQuery(
    ["studioUseranalyze", param],
    () => studioUseranalyze(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

//평가 분석
const studioEvalanalyze = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/studio/evalanalyze`, {
    ...param,
  });
  return data;
};

export const useStudioEvalanalyze = (param: any) => {
  return useQuery(
    ["studioEvalanalyze", param],
    () => studioEvalanalyze(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

//전화번호 체크
const phonecheck = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/health/phonecheck`, {
    ...param,
  });
  return data;
};

export const usePhonechecke = (param: any) => {
  return useQuery(
    ["phonecheck", param],
    () => phonecheck(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

//운동프로그램 전송
const sendexercise = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/health/sendexercise`, {
    ...param,
  });
  return data;
};

export const useSendexercise = (param: any) => {
  return useQuery(
    ["sendexercise", param],
    () => sendexercise(param),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};
