import { idText } from "typescript";

let checkNum = /[0-9]/;
let checkEng = /[a-zA-Z]/;
let checkSpc = /[~!@#$%^&*()_+|<>?:{}]/;
let checkKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

export const emailValFunc = (email: string) => {
  const re =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
};

export const passwordValFunc = (pw: string) => {
  if (
    pw.length > 5 &&
    pw.length < 13 &&
    (
      (checkNum.test(pw) && checkEng.test(pw)) 
    || (checkSpc.test(pw) && checkNum.test(pw))
    || (checkSpc.test(pw) && checkEng.test(pw))
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const nameValFunc = (name: string) => {
  const checkKor = /[가-힣]/;
  const checkEng = /[a-zA-Z]/;
  // const num = /[0-9]/;
  const checkSpc = /[~!@#$%^&*()_+|<>?:{}]/;
  if (
    !checkSpc.test(name) &&
    !checkNum.test(name) &&
    (checkKor.test(name) || checkEng.test(name)) &&
    name.length > 1 &&
    name.length < 17
  ) {
    return true;
  } else {
    return false;
  }
};

export const numberValFunc = (data: string) => {
  if (data !== "") {
    const number = Number(data);
    if (isNaN(number)) {
      return true;
    } else {
      return false;
    }
  }
};

export const onlyNumberFunc = (number: string) => {
  const regex = /[^0-9]/g;
  const result = number.replace(regex, "");
  return result;
};

export const nicknameValFunc = (nickname: string) => {
  const numKEN = /^[ㄱ-ㅎ|가-힣|A-Z|a-z|0-9|]+$/;
  if (numKEN.test(nickname) && nickname.length > 1 && nickname.length < 11) {
    return true;
  } else {
    return false;
  }
};

export const certificationNumValFunc = (nickname: string) => {
  const eng = /[A-Z]/;
  if (eng.test(nickname)) {
    return true;
  } else {
    return false;
  }
};

export const eknValFunc = (name: string) => {
  let checkEngNum = /^[가-힣|a-z|A-Z|0-9|/\s/g]+$/;
  if (name !== "") {
    if (checkEngNum.test(name)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const eknValFunc2 = (name: string) => {
  let checkEngNum = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|/\s/g]+$/;
  if (name !== "") {
    if (checkEngNum.test(name)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const enValFunc = (value: string) => {
  let checkEngNum = /^[a-z|A-Z|0-9|]+$/;
  if (checkEngNum.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const eValFunc = (value: string) => {
  if (checkEng.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const checkSpcFunc = (value: string) => {
  if (checkSpc.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const birthdayValidator = (value: string) => {
  const number = Number(value);
  if (!isNaN(number) && value?.length === 8) {
    return true;
  } else {
    return false;
  }
};
