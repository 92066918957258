const FooterPrivacyPolicyTerms = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "965px",
        position: "relative",
        gap: "16px",
        margin: "0 auto",
        padding: "120px 0 100px",
      }}
    >
      <p
        style={{
          fontSize: "24px",
          color: "#ababab",
        }}
      >
        <b>개인정보 처리방침</b>
      </p>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "14px",
          textAlign: "left",
          color: "#ababab",
        }}
      >
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          {`주식회사 엑스바디(이하 “회사”라 한다)는 개인정보보호법 등 관련 법령을 준수하며, 관련
        법령에 의거한 개인정보∙민감정보처리방침을 정하여 개인정보 보호에 최선을 다하고 있습니다.`}
        </span>
        <br />
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          {`개인정보처리방침은 이용자가 언제나 쉽게 열람할 수 있도록 서비스 초기화면을 통해 공개 하고 있으며 관련 법령, 지침, 고시 및 회사 서비스 정책의 변경에 따라 달라질 수 있습니다. 해당 내용을 자세히 읽은 후 이에 동의하시는 경우에만 검사를 진행해 주시기 바랍니다. `}
        </span>
      </p>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "16px",
          fontWeight: "500",
          textAlign: "left",
          color: "#e2e2e2",
        }}
      >
        1. 처리하는 개인정보 항목 및 목적
      </p>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "14px",
          textAlign: "left",
          color: "#ababab",
        }}
      >
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          회사는 사업운영, 제품 및 서비스의 제공 및 개선, 사용자에 대한 안내,
          효과적인 광고 개발 및 아래의 각 목적을 위하여 개인정보·민감정보 항목을
          수집하여 처리하고 있습니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          회사가 모든 이용자에게 서비스를 제공하기 위해서는 아래 항목의
          개인정보가 필요하며, 이는 가입신청자의 동의 또는 법령에 근거하여
          수집됩니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          피검사자는 본 동의에 대해 거부하실 권리가 있으나 필수항목에 대한 수집
          및 이용동의를 거부하실 경우 서비스 이용에 제한이 있을 수 있습니다.
        </span>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexGrow: "0",
          flexShrink: "0",
          width: "964px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
          }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "0",
              flexShrink: "0",
              width: "240px",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            수집시기
          </p>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              fontSize: "12px",
              color: "#e2e2e2",
            }}
          >
            수집항목
          </p>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            수집목적
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexGrow: "0",
              flexShrink: "0",
              width: "224px",
              height: "160px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                alignSelf: "stretch",
                flexGrow: "1",
                width: "224px",
                height: "144px",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              피검사자 등록 시
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexGrow: "1",
              height: "160px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                alignSelf: "stretch",
                flexGrow: "1",
                width: "335px",
                height: "144px",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "144px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                [필수]
              </span>
              <br />
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "144px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                성명, 생년월일, 이메일 주소, 휴대폰번호, 성별, 연령,
                중복가입확인정보(DI)
              </span>
              <br />
              <br />
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "144px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                [미성년자의 경우 추가 필수 수집 개인정보]
              </span>
              <br />
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "144px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                법정대리인의 성명 , 이메일 주소, 휴대전화번호,
                중복가입확인정보(DI)
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexGrow: "1",
              height: "160px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                alignSelf: "stretch",
                flexGrow: "1",
                width: "335px",
                height: "144px",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "144px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                가입 의사 확인, 서비스 가입/변경/해지, 서비스 제공여부
                결정(연령확인 등) 및 중복가입여부 확인,
              </span>
              <br />
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "144px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                공지 사항 전달, 피검사자 참여 공간 운영, 피검사자 설문 조사,
                개인 맞춤형 서비스 제공, 광고성 정보∙제휴 서비스 제공 및 안내,
                상품 배송지 주소 및 연락처 확인,
              </span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexGrow: "0",
              flexShrink: "0",
              height: "90px",
              width: "224px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                alignSelf: "stretch",
                flexGrow: "1",
                width: "224px",
                height: "74px",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              피검사자 측정 시
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexGrow: "1",
              height: "90px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                alignSelf: "stretch",
                flexGrow: "1",
                width: "335px",
                height: "74px",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "74px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                [필수]
              </span>
              <br />
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "74px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                검사회차, 최초검사일, 검사결과 수치, 검사결과 이미지
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexGrow: "1",
              height: "90px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                alignSelf: "stretch",
                flexGrow: "1",
                width: "335px",
                height: "74px",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "74px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                개인 맞춤형 서비스 제공
              </span>
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexGrow: "0",
              flexShrink: "0",
              height: "90px",
              width: "224px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                alignSelf: "stretch",
                flexGrow: "1",
                width: "224px",
                height: "74px",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              컨테스트 이벤트 및 프로모션 이용시
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexGrow: "1",
              height: "90px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                alignSelf: "stretch",
                flexGrow: "1",
                width: "335px",
                height: "74px",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "74px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                [필수]
              </span>
              <br />
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "74px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                성명, 휴대폰 번호, 이메일, 거주지, 계좌정보(은행명, 예금주명,
                계좌번호)
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexGrow: "1",
              height: "90px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                alignSelf: "stretch",
                flexGrow: "1",
                width: "335px",
                height: "74px",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              <span
                style={{
                  alignSelf: "stretch",
                  flexGrow: "1",
                  width: "335px",
                  height: "74px",
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#e2e2e2",
                }}
              >
                이벤트 당첨 및 컨테스트 수상시 상품 배송
              </span>
            </p>
          </div>
        </div>
      </div>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "16px",
          fontWeight: "500",
          textAlign: "left",
          color: "#e2e2e2",
        }}
      >
        2. 개인정보의 처리 및 보유기간
      </p>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          textAlign: "left",
        }}
      >
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          1. 회사는 피검사자의 개인정보 동의 일로부터 피검사자가 개인정보 파기를
          요청할 때 까지 보유하며, 피검사자가 개인정보 파기를 요청할 경우
          개인정보보호법 제39조의6에 따라 피검사자의 개인정보를 지체없이
          파기하거나 별도로 분리하여 보관합니다. 단, 관계법령에 따라 보관되는
          정보는 예외로 합니다.
        </span>
        <br />
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            fontWeight: "500",
            textAlign: "left",
            color: "#e2e2e2",
          }}
        >
          ㆍ관련 법령에 의거한 개인정보의 보유 및 이용기간
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          1) 상업장부와 영업에 관한 중요서류 및 전표 : 중요서류-10년/
          전표-5년(상법)
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          2) 거래에 관한 장부 및 증빙서류 : 5 년(국세기본법, 법인세법,
          부가가치세법 등)
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          3) 통신사실확인자료 제공 시 필요한 로그기록자료, IP 주소 등 : 3
          개월(통신비밀보호법)
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          4) 표시/광고에 관한 기록 : 6 개월(전자상거래 등에서의 소비자보호에
          관한 법률)
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          5) 계약 또는 청약철회 등에 관한 기록 : 5 년(전자상거래 등에서의
          소비자보호에 관한 법률)
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          6) 대금결제 및 재화 등의 공급에 관한 기록 : 5 년(전자상거래 등에서의
          소비자보호에 관한 법률)
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          7) 소비자의 불만∙분쟁 처리에 관한 기록 : 3 년(전자상거래법등에서의
          소비자보호에 관한 법률)
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          8) 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3 년(신용정보의 이용
          및 보호에 관한 법률)
        </span>
        <br />
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          9) 본인확인에 관한 기록 : 6 개월(정보통신망 이용촉진 및 정보보호 등에
          관한 법률)
        </span>
      </p>
      <p
        style={{
          flexGrow: "0",
          flexShrink: "0",
          width: "476px",
          fontSize: "16px",
          fontWeight: "500",
          textAlign: "left",
          color: "#e2e2e2",
        }}
      >
        3. 개인정보의 제3자 제공
      </p>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "14px",
          textAlign: "left",
          color: "#ababab",
        }}
      >
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          1. 회사는 피검사자의 개인정보를 제 1조(처리하는 피검사자의 개인정보
          항목 및 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의,
          법률의 특별한 규정 등 법령에 명시된 경우에만 개인정보를 제 3자에게
          제공합니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          2. 회사는 이용자에게 동의받은 범위에 대하여 제 3자에게 개인정보를
          제공하고 있습니다.
        </span>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexGrow: "0",
          flexShrink: "0",
          width: "964px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
          }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "0",
              flexShrink: "0",
              width: "100px",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            연결서비스
          </p>
          <p
            style={{
              width: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              fontSize: "12px",
              color: "#e2e2e2",
            }}
          >
            제공받는자
          </p>
          <p
            style={{
              width: "160px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            이전되는 개인정보 항목
          </p>
          <p
            style={{
              width: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            이전되는 국가
          </p>
          <p
            style={{
              width: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            이전일시
          </p>
          <p
            style={{
              width: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            이전방법
          </p>
          <p
            style={{
              width: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            이전받는자
          </p>
          <p
            style={{
              width: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            이용목적
          </p>
          <p
            style={{
              width: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            보유 및 이용기간
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
          }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "0",
              flexShrink: "0",
              width: "84px",
              padding: "8px",
              height: "100px",
              position: "relative",
              gap: "10px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            엑스바디 모바일 앱 서비스
          </p>
          <p
            style={{
              width: "82.5px",
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "100px",
              position: "relative",
              gap: "10px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              fontSize: "12px",
              color: "#e2e2e2",
            }}
          >
            ㈜엑스바디
          </p>
          <p
            style={{
              width: "141px",
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "100px",
              position: "relative",
              gap: "10px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            제 1조 [필수] 수집항목
          </p>
          <p
            style={{
              width: "82.5px",
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "100px",
              position: "relative",
              gap: "10px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            대한민국
          </p>
          <p
            style={{
              width: "82.5px",
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "100px",
              position: "relative",
              gap: "10px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            개인정보 제3자 제공동의 시
          </p>
          <p
            style={{
              width: "82.5px",
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "100px",
              position: "relative",
              gap: "10px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            온라인 전송
          </p>
          <p
            style={{
              width: "82.5px",
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "100px",
              position: "relative",
              gap: "10px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            ㈜엑스바디
          </p>
          <p
            style={{
              width: "82.5px",
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "100px",
              position: "relative",
              gap: "10px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            검사정보에 대한 모바일 앱 서비스와의 연동
          </p>
          <p
            style={{
              width: "82.5px",
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: "1",
              height: "100px",
              position: "relative",
              gap: "10px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            서비스 이용 종료 시까지
          </p>
        </div>
      </div>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "14px",
          textAlign: "left",
          color: "#ababab",
        }}
      >
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          3. 회사는 원칙적으로 피검사자의 사전 동의 없이 피검사자의 개인정보를
          제3자에게 제공하지 않습니다. 피검사자의 개인정보를 타 피검사자 등
          제3자에게 공유할 필요가 있는 경우에는 제공받는 자, 제공목적, 제공 정보
          항목, 이용 및 보유기간 등을 피검사자에게 고지하여 동의를 구한 뒤
          제공합니다. 다만 관련 법령에 따르는 경우는 예외로 할 수 있습니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (1) 제3자에게 제공되는 경우{" "}
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          가. 피검사자가 거래 이행 및 원활한 서비스 제공 등을 위해 사전에 제3자
          제공에 동의한 경우
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          나. 관계 법령에 의하거나, 수사 목적으로 법령에 정해진 절차와 방법에
          따라 수사기관의 요구가 있는 경우
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          다. 회사의 분할, 합병, 영업양수도 등의 사유가 있는 경우
        </span>
      </p>
      <p
        style={{
          flexGrow: "0",
          flexShrink: "0",
          width: "476px",
          fontSize: "16px",
          fontWeight: "500",
          textAlign: "left",
          color: "#e2e2e2",
        }}
      >
        4. 개인정보의 취급위탁
      </p>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "14px",
          textAlign: "left",
          color: "#ababab",
        }}
      >
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          회사는 서비스 향상을 위해 개인정보의 처리를 다른 회사 등에 위탁할 수
          있습니다. 회사는 개인정보 처리 위탁 시 피검사자의 개인정보가 안전하게
          처리될 수 있도록 관리·감독하며 다른 목적으로 피검사자의 개인정보를
          처리하지 않도록 제한합니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (1) 개인정보의 처리를 위탁하는 경우에는 미리 그 사실을 피검사자에게
          고지하겠습니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (2) 개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스
          제공자의 개인정보보호 관련지시 엄수, 개인정보에 관한 비밀유지, 제3자
          제공의 금지 및 사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을
          서면 또는 전자적으로 보관하겠습니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
      </p>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "16px",
          fontWeight: "500",
          textAlign: "left",
          color: "#e2e2e2",
        }}
      >
        5. 개인정보 파기절차 및 방법
      </p>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "14px",
          textAlign: "left",
          color: "#ababab",
        }}
      >
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          회사는 개인정보 보유기간의 경과 혹은 개인정보의 수집 및 이용목적의
          달성 등 개인정보가 불필요하게 되었을 때에는 해당 개인정보를 지체 없이
          파기합니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (1) 파기절차{" "}
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          가. 회사는 파기 사유가 발생한 개인정보를 개인정보보호책임자의 승인을
          거쳐 파기합니다.
        </span>
        <br />
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          나. 피검사자가 피검사자가입 또는 서비스 신청 등을 위해 입력한 정보는
          목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 잠금 장치가
          있는 서류 보관함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에
          따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          다. 동 개인정보는 관련 법령에 의한 경우가 아니고서는 보유 목적 이외의
          다른 목적으로 이용되지 않습니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (2) 파기방법{" "}
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          가. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
          방법을 사용하여 파기합니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          나. 종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기합니다.
        </span>
      </p>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "16px",
          fontWeight: "500",
          textAlign: "left",
          color: "#e2e2e2",
        }}
      >
        6. 개인정보의 안정성 확보 대책
      </p>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "14px",
          textAlign: "left",
          color: "#ababab",
        }}
      >
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          회사는 피검사자의 개인정보를 처리함에 있어 안정성을 확보하기 위하여
          다음과 같이 대책을 강구하고 있습니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (1) 개인정보의 암호화{" "}
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          피검사자의 개인정보는 비밀번호에 의해 보호되며 중요한 데이터는 파일 및
          전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등 다른 보안
          기능을 사용하여 보호하고 있습니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (2) 해킹 등에 대비한 기술적 대책{" "}
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          회사는 해킹이나 컴퓨터 바이러스 등에 의해 피검사자의 개인 정보가
          유출되거나 훼손되는 것을 막기 위해, 보안프로그램을 설치하고 주기적으로
          갱신·점검하고 있습니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (3) 개인정보 처리 시스템 접근 제한{" "}
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          회사의 개인정보관련 취급 직원은 제한된 소수의 담당자에 한정시키고 있고
          이를 위한 별도의 비밀번호를 부여하여 수시로 변경하고 있습니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (4) 개인정보 취급 직원의 교육{" "}
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          회사는 개인정보 취급 직원에 대해 새로운 보안 기술 습득 및 개인 정보
          보호 의무에 대한 정기적인 교육을 실시하고 있습니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (5) 비인가자에 대한 출입 통제{" "}
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          개인정보를 보관하고 있는 개인정보처리시스템의 물리적 보관 장소를
          별도로 두고 이에 대한 출입통제 절차를 수립, 운영하고 있습니다.
        </span>
      </p>
      <p
        style={{
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
          fontSize: "16px",
          fontWeight: "500",
          textAlign: "left",
          color: "#e2e2e2",
        }}
      >
        7. 개인정보보호책임자{" "}
      </p>
      <p>
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          피검사자는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련
          민원을 개인정보보호책임자 또는 담당부서에 문의하실 수 있습니다. 회사는
          피검사자들의 문의사항에 대해 신속하고 충분한 답변을 드릴 것입니다.
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          ㆍ개인정보보호책임자 : 김미숙
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          ㆍ전화번호 : 02-6925-6081
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          ㆍ메일 : secure@exbody.com
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "16px",
            fontWeight: "500",
            textAlign: "left",
            color: "#e2e2e2",
          }}
        >
          8. 개인정보침해에 대한 신고 및 상담
        </span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            alignSelf: "stretch",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
          문의하시기 바랍니다.
        </span>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexGrow: "0",
          flexShrink: "0",
          width: "965px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
          }}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "322.33px",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            기관
          </p>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "322.33px",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              color: "#e2e2e2",
              fontSize: "12px",
            }}
          >
            홈페이지
          </p>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "322.33px",
              height: "32px",
              position: "relative",
              gap: "10px",
              background: "#272727",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
              fontSize: "12px",
              color: "#e2e2e2",
            }}
          >
            전화번호
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              개인정보침해신고센터
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              http://privacy.kisa.or.kr/kor/main.jsp
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                fontSize: "13px",
                textAlign: "center",
                color: "#e2e2e2",
              }}
            >
              (국번없이) 118
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              개인정보분쟁조정위원회
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              https://www.kopico.go.kr/
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                fontSize: "13px",
                textAlign: "center",
                color: "#e2e2e2",
              }}
            >
              1833-6972
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              대검찰청 사이버수사과
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              http://www.spo.go.kr/spo/index.jsp
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                fontSize: "13px",
                textAlign: "center",
                color: "#e2e2e2",
              }}
            >
              (국번없이) 1301
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexGrow: "0",
            flexShrink: "0",
            width: "965px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              경찰청 사이버안전국
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                width: "309px",
                fontSize: "13px",
                textAlign: "left",
                color: "#e2e2e2",
              }}
            >
              http://cyberbureau.police.go.kr/index.do
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              alignSelf: "stretch",
              flexGrow: "0",
              flexShrink: "0",
              width: "306.33px",
              position: "relative",
              gap: "10px",
              padding: "8px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#323232",
            }}
          >
            <p
              style={{
                flexGrow: "0",
                flexShrink: "0",
                fontSize: "13px",
                textAlign: "center",
                color: "#e2e2e2",
              }}
            >
              (국번없이) 182
            </p>
          </div>
        </div>
      </div>
      <p
        style={{
          flexGrow: "0",
          flexShrink: "0",
          width: "476px",
          fontSize: "16px",
          fontWeight: "500",
          textAlign: "left",
          color: "#e2e2e2",
        }}
      >
        9. 고지의 의무
      </p>
      <p
        style={{
          flexGrow: "0",
          flexShrink: "0",
          width: "476px",
          fontSize: "14px",
          textAlign: "left",
          color: "#ababab",
        }}
      >
        <span
          style={{
            flexGrow: "0",
            flexShrink: "0",
            width: "476px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (1) 본 개인정보처리방침은 법령, 정부의 정책 또는 회사 내부 정책의 변경
          등 필요에 따라 변경될 수 있으며 내용의 추가, 삭제 및 수정이 있을
          경우에는 개정 최소 7일 전부터 엑스바디 서비스 홈페이지를 통해
          고지합니다.
        </span>
        <br />
        <span
          style={{
            flexGrow: "0",
            flexShrink: "0",
            width: "476px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            flexGrow: "0",
            flexShrink: "0",
            width: "476px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          (2) 본 개인정보처리방침은 2023년 5월 16일 부터 적용됩니다.
        </span>
        <br />
        <span
          style={{
            flexGrow: "0",
            flexShrink: "0",
            width: "476px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            flexGrow: "0",
            flexShrink: "0",
            width: "476px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          - 공고일자 : 2023년 5월 16일
        </span>
        <br />
        <span
          style={{
            flexGrow: "0",
            flexShrink: "0",
            width: "476px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        ></span>
        <br />
        <span
          style={{
            flexGrow: "0",
            flexShrink: "0",
            width: "476px",
            fontSize: "14px",
            textAlign: "left",
            color: "#ababab",
          }}
        >
          - 시행일자 : 2023년 5월 16일
        </span>
      </p>
    </div>
  );
};

export default FooterPrivacyPolicyTerms;
