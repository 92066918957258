//object 값 업데이트
const updateObject = (oldObject: any, newValues: any) => {
  return Object.assign({}, oldObject, newValues);
};

const SET_TOKEN = "SET_TOKEN";
export const set_token = (token: string | null) => {
  return {
    type: SET_TOKEN,
    param: token,
  };
};

//action
//사용자 index
export const SET_USER_INDEX = "SET_USER_INDEX";
export const set_user_index = (usridx: number) => {
  return {
    type: SET_USER_INDEX,
    param: usridx,
  };
};

//header에 공통으로 사용할 정보
export const SET_HEADER_INFO = "SET_HEADER_INFO";
export const set_header_info = (headerInfo: any) => {
  return {
    type: SET_HEADER_INFO,
    param: headerInfo,
  };
};

//자동 로그인
export const SET_AUTO_LOGIN = "SET_AUTO_LOGIN";
export const set_auto_login = (autoLogin: boolean) => {
  return {
    type: SET_AUTO_LOGIN,
    param: autoLogin,
  };
};

//sns 정보 id
export const SET_SNS_INFO = "SET_SNS_INFO";
export const set_sns_info = (snsInfo: any) => {
  return {
    type: SET_SNS_INFO,
    param: snsInfo,
  };
};

export const SET_SCROLL_Y = "SET_SCROLL_Y";
export const set_scroll_y = (scrollY: any) => {
  return {
    type: SET_SCROLL_Y,
    param: scrollY,
  };
};

export const SET_EX_PAGE = "SET_EX_PAGE";
export const set_ex_page = (exPage: any) => {
  return {
    type: SET_EX_PAGE,
    param: exPage,
  };
};

export const SET_IS_UNITY = "SET_IS_UNITY";
export const set_is_unity = (isUnity: any) => {
  return {
    type: SET_IS_UNITY,
    param: isUnity,
  };
};

export const SET_EXAPPARATUS_LOGIN = "SET_EXAPPARATUS_LOGIN";
export const set_exapparatus_login = (exapparatusLogin: any) => {
  return {
    type: SET_EXAPPARATUS_LOGIN,
    param: exapparatusLogin,
  };
};

export const SET_PROGRAM_IDX = "SET_PROGRAM_IDX";
export const set_program_idx = (programidx: any) => {
  return {
    type: SET_PROGRAM_IDX,
    param: programidx,
  };
};

export const SET_COMPANY_IDX = "SET_COMPANY_IDX";
export const set_company_idx = (companyIdx: any) => {
  return {
    type: SET_COMPANY_IDX,
    param: companyIdx,
  };
};

export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const set_company_name = (companyName: any) => {
  return {
    type: SET_COMPANY_NAME,
    param: companyName,
  };
};

export const SET_CREATOR_INDEX = "SET_CREATOR_INDEX";
export const set_creator_index = (creatorIdx: any) => {
  return {
    type: SET_CREATOR_INDEX,
    param: creatorIdx,
  };
};

export const SET_STUDIO_PROGRAMS = "SET_STUDIO_PROGRAMS";
export const set_studio_programs = (studioPrograms: any) => {
  return {
    type: SET_STUDIO_PROGRAMS,
    param: studioPrograms,
  };
};

export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
export const set_category_list = (categorylist: any) => {
  return {
    type: SET_CATEGORY_LIST,
    param: categorylist,
  };
};

type Type = {
  usridx: number;
  headerInfo: {
    credit: number;
    point: number;
    usrnick: string;
    usrname: string;
    profile_url: string | null;
    userbodyinfo: boolean;
    usrtype: string;
    channeladdress: string;
    myproductlist: boolean;
  } | null;
  autoLogin: boolean;
  snsInfo: any;
  token: string | null;
  scrollY: number;
  exPage: number | null;
  isUnity: boolean;
  exapparatusLogin: string | null;
  programidx: number;
  creatorIdx: number;
  studioPrograms: number[];
  categorylist: number[];
  companyIdx: number;
  companyName: string;
};

const initialState: Type = {
  usridx: 0,
  headerInfo: null,
  autoLogin: false,
  snsInfo: null,
  token: null,
  scrollY: 0,
  exPage: 1,
  isUnity: false,
  exapparatusLogin: null,
  programidx: 0, //161, // 0,
  creatorIdx: 0,
  studioPrograms: [1],
  categorylist: [],
  companyIdx: 0,
  companyName: "",
};

const local = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_TOKEN":
      return updateObject({ ...state }, { token: action.param });

    case "SET_USER_INDEX":
      return updateObject({ ...state }, { usridx: action.param });

    case "SET_HEADER_INFO":
      return updateObject({ ...state }, { headerInfo: action.param });

    case "SET_AUTO_LOGIN":
      return updateObject({ ...state }, { autoLogin: action.param });

    case "SET_SNS_INFO":
      return updateObject({ ...state }, { snsInfo: action.param });

    case "SET_SCROLL_Y":
      return updateObject({ ...state }, { scrollY: action.param });

    case "SET_EX_PAGE":
      return updateObject({ ...state }, { exPage: action.param });

    case "SET_IS_UNITY":
      return updateObject({ ...state }, { isUnity: action.param });

    case "SET_EXAPPARATUS_LOGIN":
      return updateObject({ ...state }, { exapparatusLogin: action.param });

    case "SET_PROGRAM_IDX":
      return updateObject({ ...state }, { programidx: action.param });

    case "SET_CREATOR_INDEX":
      return updateObject({ ...state }, { creatorIdx: action.param });

    case "SET_STUDIO_PROGRAMS":
      return updateObject({ ...state }, { studioPrograms: action.param });

    case "SET_CATEGORY_LIST":
      return updateObject({ ...state }, { categorylist: action.param });
    case "SET_COMPANY_IDX":
      return updateObject({ ...state }, { companyIdx: action.param });
    case "SET_COMPANY_NAME":
      return updateObject({ ...state }, { companyName: action.param });
    default:
      return state;
  }
};

export default local;
