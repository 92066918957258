import styled from "styled-components";
import { font } from "assets/styled/fonts";
import { root } from "assets/styled/colors";

interface HeaderProps {
  logoSize: string;
}

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #111;
  width: calc(100% - 48px);
  height: 64px;
  padding: 0 24px;
  z-index: 100;
`;

export const Time = styled.p`
  ${font.h2_medium};
  color: ${root.white};
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
`;

export const Logout = styled.p``;

export const Logo = styled.p<HeaderProps>`
  svg {
    fill: ${root.white};
    height: ${(props) => (props.logoSize ? props.logoSize : "34px;")};
  }
`;

export const User = styled.div`
  ${font.caption1_regular};
  color: ${root.white};
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 24px;
`;
