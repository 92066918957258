import { useTranslation } from "react-i18next";

//style
import "./ExstartBtn.scss";

//img
import { ReactComponent as ArrowRightIcon } from "assets/svg/ic_arrow_right.svg";

const ExStartBtn = ({
  text = "ExercisePage_ContentDetail_Btn_StartExercise",
  action = true,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={action ? `exStartBtn` : `exStartBtn no`}
      onClick={props.onClick && action && props.onClick}
    >
      <p className={`backGradient`}></p>
      <p className={`textArrow`}>
        <span className={`h4_medium text`}>{t(`${text}`)}</span>
        <span className={`icon`}>
          <ArrowRightIcon />
        </span>
      </p>
    </div>
  );
};

export default ExStartBtn;
