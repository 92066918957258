import React from "react";
import { useTranslation } from "react-i18next";
import { cAPIMessagePack } from "utils/cAPI";
import parser from "html-react-parser";
import { set_creator_index } from "modules/local";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

//scss
import "./DayBtn.scss";

//icon
import { ReactComponent as ArrowRightIcon } from "assets/svg/ic_arrow_right.svg";

//global
import { stringFormat } from "utils/commons";

const DayBtn = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClick = () => {
    if (props?.category === 2) {
      dispatch(set_creator_index(props?.creatorIdx));
      navigate(
        `/videoEXStart?week=${props.week}&day=${props.day}&_id=${props.exCode}`
      );
    } else {
      cAPIMessagePack(
        `exbodyKiosk:?type=1&_id=${props.exCode}&usridx=${props.usridx}&weeks=${props.week}&daysAWeek=${props.day}`
      );
    }
  };

  return (
    <p
      className={
        props.open
          ? `dayBtn open`
          : props.today
          ? `dayBtn today`
          : props.on
          ? `dayBtn on`
          : `dayBtn`
      }
      onClick={props.open ? onClick : () => {}}
    >
      {props.open ? (
        <>
          {parser(
            stringFormat(
              t("ExercisePage_ContentDetail_DayStartExercise_Title"),
              `<b className="text">${props.day}</b>`
            )
          )}
          <i>
            <ArrowRightIcon />
          </i>
        </>
      ) : props.today ? (
        <>
          {parser(
            stringFormat(
              t("ExercisePage_ContentDetail_DayStartExercise_Title"),
              `<b className="text">${props.day}</b>`
            )
          )}
        </>
      ) : (
        props.day
      )}
    </p>
  );
};

export default DayBtn;
