import api from "../utils/api";
import { URL } from "../datas/common";

export const postApiPrivate = async (
  pathName: string,
  param: any,
  callback: any
) => {
  const url = `${URL}/${pathName}`;
  await api
    .post(url, param, { withCredentials: true })
    .then(function (res: any) {
      let response = res?.data;

      callback(response);
    })
    .catch(function (err: any) {
      console.log(url, err);
      return;
    });
};
