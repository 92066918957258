import "./Studio.scss";
import HomeStudio from "components/studio/homeStudio/HomeStudio";
import BackLayout from "components/backLayout/BackLayout";

const Studio = () => {
  return (
    <BackLayout bgColor="black2" maxWidth="100%">
      <HomeStudio />
    </BackLayout>
  );
};

export default Studio;
