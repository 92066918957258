import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ExerciseKind.scss";
import { ReactComponent as QuestionCircleIconLined } from "assets/svg/ic_question_circle_lined.svg";
import { ReactComponent as ChevronRightIcon } from "assets/svg/ic_chevron_right.svg";
import { ReactComponent as LongArrowDownRightIcon } from "assets/svg/ic_long_arrow_down_right.svg";
import ExerciseDescModal from "components/modal/exerciseDescMadal/ExerciseDescModal";

const ExerciseKind = ({ ...props }) => {
  const { t } = useTranslation();
  const { data, programDatas, countTypeArr } = props;
  const [isModal, setModal] = useState(false);

  return (
    <div className={`exerciseKind`}>
      {props.type === "group" ? (
        <p className={`chevron group`}>
          <LongArrowDownRightIcon />
        </p>
      ) : (
        <p className={`chevron`}>
          <ChevronRightIcon />
        </p>
      )}
      <div className={`exerciseKindBorder`}>
        <div className={`exerciseKindLeft`}>
          <p className={`kindImg`}>
            <img src={`${data.exerciseImage}`} alt="kindImg" />
          </p>
          <p className={`body_medium kindName`}>{t(data.nameString)}</p>
        </div>
        <div className={`exerciseKindRight`}>
          <div className={`exerciseKindInputArea number`}>
            <p className={`caption1_regular`}>
              {t(
                countTypeArr?.filter(
                  (el: CountType) => data.countType.toString() === el.key
                )[0].title
              )}
            </p>
            <p className={`caption3_medium countValue`}>{data.count}</p>
          </div>
          <div
            className={
              props.groupType === 1
                ? `exerciseKindInputArea set no`
                : `exerciseKindInputArea set`
            }
          >
            <p className={`caption1_regular`}>
              {t("ExercisePage_ContentDetail_ExerciseSet_Name")}
            </p>
            <p className={`caption3_medium countValue`}>{data.set}</p>
          </div>
          <p className={`questionIcon`} onClick={() => setModal(true)}>
            <QuestionCircleIconLined />
          </p>
        </div>
      </div>
      <ExerciseDescModal
        onoff={isModal}
        handleClose={() => setModal(!isModal)}
        title={data.nameString}
        desc={data.infoString}
        desc2={data.detailPrecautionString}
        programDatas={programDatas}
        src={data.exerciseImage}
        movie={data.guideMovie}
      />
    </div>
  );
};

export default ExerciseKind;
