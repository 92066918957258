import styled from "styled-components";
import { font } from "assets/styled/fonts";
import { root } from "assets/styled/colors";

export const ExerciseNavBox = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${root.black};
  margin-bottom: 64px;
  position: sticky;
  top: 64px;
  z-index: 80;
`;

export const ExerciseNav = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
`;

export const Nav = styled.p`
  color: ${root.white};
  opacity: 0.5;
  ${font.h2_bold};
  height: 76px;
  line-height: 76px;
`;
