import styled from "styled-components";
import { font } from "assets/styled/fonts";
import { root } from "assets/styled/colors";

export const DormantTermination = styled.div``;

export const Modal = styled.div`
  position: fixed;
  top: 24px;
  left: 50%;
  opacity: 1;
  transform: translateX(-50%);
  display: inline-block;
  width: 654px;
  border-radius: 8px;
  border: solid 2px var(--dark-opacity-80);
  background-color: var(--black);
  padding: 30px 40px;
  text-align: center;
  animation-duration: 0.4s;
  animation-name: open;
  z-index: 100;
`;

export const Title = styled.p`
  ${font.body_medium};
  color: ${root.white};
  margin-bottom: 24px;
`;

export const Text = styled.p`
  ${font.caption1_regular};
  color: ${root.darkgrey60};
`;

export const Box = styled.div`
  // padding: 8px 12px;
  // border-radius: 4px;
  // border: solid 1px ${root.darkopacity80};
  // background-color:  ${root.darkopacity10};
  width: 240px;
  margin: 16px auto;
  ${Text} {
    margin: 8px 0;
    color: ${root.white};
    span {
      margin-left: 4px;
    }
  }
`;

export const Btns = styled.p`
  display: flex;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 28px;
  button {
    width: 74px;
  }
`;

export const InputArea = styled.div`
  display: flex;
  gap: 8px;
  margin: 16px auto;
  width: 500px;
  .rectangelTextField .inputArea,
  .rectangleSelect .inputArea {
    height: 34px;
    min-height: 34px;
  }
  .rectangleSelect .menus {
    top: 34px;
    z-index: 1000;
    width: calc(100% + -1px) !important;
  }
  .rectangelTextField .error span {
    min-width: 194px;
    margin-left: 0;
  }
  .dayInput .rectangelTextField .error span {
    min-width: 79px;
    margin-left: 0;
  }
`;

export const Label = styled.span`
  display: inline-block;
  width: 100px;
  min-width: 160px;
  color: ${root.darkgrey60};
  line-height: 34px;
`;

export const DayArea = styled.div`
  display: flex;
  gap: 8px;
`;
