import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
//style
import * as S from "./BackLayout.Styled";

//svg
import { ReactComponent as IconUndoCircleLined } from "assets/svg/ic_undo_circle_lined.svg";

type Props = {
  children: string | JSX.Element | JSX.Element[];
  maxWidth?: string;
  bgColor?: string;
};

const BackLayout = ({ children, maxWidth, bgColor }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const onClickBack = () => {
    if (pathname === "/studio" || pathname === "/userInfo") {
      return navigate("/");
    } else if (pathname === "/exercise") {
      return navigate("/");
    } else if (pathname.includes("/exercise/detail")) {
      return navigate(-1); //history.push("/exercise")
    } else {
      return navigate(-1);
    }
  };

  return (
    <S.BackLayout bgColor={bgColor}>
      <S.Back onClick={onClickBack}>
        <IconUndoCircleLined />
        <S.Text>{t("Kiosk_Back_BTN")}</S.Text>
      </S.Back>
      <S.Container maxWidth={maxWidth} bgColor={bgColor}>
        {children}
      </S.Container>
    </S.BackLayout>
  );
};

export default BackLayout;
