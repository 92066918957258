import Pagination from "react-js-pagination";
import "./PageNav.scss";
import { ReactComponent as ChevronLeftIcon } from "assets/svg/ic_chevron_left.svg";
import { ReactComponent as ChevronRightIcon } from "assets/svg/ic_chevron_right.svg";
import { ReactComponent as ChevronLeft2Icon } from "assets/svg/ic_chevron_left_2.svg";
import { ReactComponent as ChevronRight2Icon } from "assets/svg/ic_chevron_right_2.svg";

const PageNav = ({ ...props }) => {
  return (
    <Pagination
      activePage={props?.clickPage}
      itemsCountPerPage={props.itemsCountPerPage}
      totalItemsCount={props?.totalItemsCount}
      pageRangeDisplayed={props.pageRangeDisplayed}
      onChange={props?.handleClickPage}
      firstPageText={<ChevronLeft2Icon />}
      prevPageText={<ChevronLeftIcon />}
      lastPageText={<ChevronRight2Icon />}
      nextPageText={<ChevronRightIcon />}
    />
  );
};

export default PageNav;
