import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./SquareCard.scss";

//data
import { defaultImg } from "datas/Image";

//store
import { set_scroll_y, set_ex_page } from "modules/local";

//component
import ExerciseInfo from "components/infos/exerciseInfo/ExerciseInfo";
import SquareTextBox from "components/commons/squareTextBox/SquareTextBox";
import VideoSymbol from "components/symbol/VideoSymbol";
import KEMASymbol from "components/symbol/KEMASymbol";
import SchoolSymbol from "components/symbol/SchoolSymbol";

const SquareCard = ({ ...props }) => {
  const { t } = useTranslation();
  const { data, commonCode, clickPage } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState<{
    key: string;
    title: string;
    title2: string;
  }>({
    key: "",
    title: "",
    title2: "",
  });
  const [codeType, setCodeType] = useState<any>(null);

  useEffect(() => {
    if (commonCode) {
      let type = commonCode?.filter((el: any) => el[0] === "exerciseType");
      setCodeType(type);
    }
  }, [commonCode]);

  useEffect(() => {
    if (codeType && data) {
      for (const type of codeType) {
        if (type[1] === data?.exerciseType?.toString()) {
          const newType = { key: type[1], title: type[2], title2: type[4] };
          setType(newType);
        }
      }
    }
  }, [codeType]);

  const onClickCard = () => {
    navigate(`/exercise/detail?exconde=${data._id}`);
    dispatch(set_scroll_y(window.scrollY));
    dispatch(set_ex_page(clickPage));
  };

  return (
    <div className={`squareCardContainer`}>
      {data ? (
        <div className={`squareCard`}>
          {props.to === "none" ? (
            <div className={`card1`}>
              <div
                className={
                  data.exerciseImage
                    ? `programImgArea`
                    : `programImgArea no_data2`
                }
              >
                <p className={`programImg`}>
                  {data.exerciseImage && (
                    <img
                      src={
                        data?.exerciseImage.includes("http")
                          ? data?.exerciseImage
                          : `/exercise/${data?.exerciseImage}.png`
                      }
                      alt="exerciseImage"
                    />
                  )}
                </p>
              </div>
              <div className={`testArea`}>
                <p className={`trainingType`}>
                  <SquareTextBox text={type.title} />
                </p>
                <div className={`info`}>
                  <ExerciseInfo
                    title={data?.title}
                    time={data?.avgTime}
                    count={data?.totalDay}
                    commonCode={commonCode}
                    data={data}
                    type="rectangle"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={`card1`} onClick={onClickCard}>
              <div
                className={
                  data.exerciseImage
                    ? `programImgArea`
                    : `programImgArea no_data2`
                }
              >
                <p className={`programImg`}>
                  {data.exerciseImage && (
                    <img
                      src={
                        data?.exerciseImage.includes("http")
                          ? data?.exerciseImage
                          : `/exercise/${data?.exerciseImage}.png`
                      }
                      alt="exerciseImage"
                    />
                  )}
                </p>
              </div>
              <div className={`testArea`}>
                <div className={`trainingType`}>
                  {data?.libraryGroup === 5 && <VideoSymbol />}
                  {data?.libraryGroup === 6 && <KEMASymbol />}
                  {data?.libraryGroup === 9 && <SchoolSymbol />}

                  <SquareTextBox text={t(type.title2)} />
                </div>
                <div className={`info`}>
                  <ExerciseInfo
                    title={data?.title}
                    time={data?.avgTime}
                    count={data?.totalDay}
                    commonCode={commonCode}
                    data={data}
                    type="rectangle"
                  />
                </div>
              </div>
            </div>
            // </Link>
          )}
        </div>
      ) : (
        <div className={`squareCard`}>
          <div className={`card1`}>
            <div className={`programImgArea no_data2`}>
              <p className={`programImg`}></p>
              {!props.profileImgNo && (
                <p className={`profileImg`}>
                  <img src={defaultImg} alt="" />
                </p>
              )}
            </div>
            <p
              className={`trainingType no_data`}
              style={{ marginTop: "4px" }}
            ></p>
            <div className={`testArea`}>
              <div className={`infoArea`}>
                <div className={`infoPadding`}>
                  <div className={`info`}>
                    <ExerciseInfo type="rectangle" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SquareCard;
