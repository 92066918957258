import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./ExerciseTypeRectangle.scss";

import { exerciseTools2, bodyCommonCode } from "datas/Exercise";

interface Props {
  commonCode: any[] | undefined;
  data?: ExTypeRectangleType;
  key?: string;
}
const ExerciseTypeRectangle = ({ commonCode, data }: Props) => {
  const { t } = useTranslation();
  const [isToolBody, setToolBody] = useState<ExIconType[] | null>(null);
  // const [tagHideCount, setTagHideCount] = useState();
  const tagHideCount = localStorage.getItem("language") === "en" ? 3 : 3;

  useEffect(() => {
    let mainTag = data?.exerciseMainTagId?.sort(function (a: any, b: any) {
      return b < 20 && a < 20 ? a : b - a;
    });

    let newArr: any = [];
    if (commonCode && data && mainTag) {
      for (const tag of mainTag) {
        for (const element of commonCode) {
          if (
            data.category === 2 ||
            (data.category === 1 && data.libraryGroup === 9)
          ) {
            if (element[1] === tag.toString()) {
              newArr.push(element[4]);
            }
          } else {
            if (element[0] === "exerciseMainTagId") {
              if (element[3] === 2 && tag.toString() === element[1]) {
                const newData = { icon: {}, type: element[3] };
                bodyCommonCode.map((body: ExCommonCodeType2) => {
                  if (element[4] === body.text) {
                    newData.icon = body.icon;
                  }
                });
                newArr.push(newData);
              } else if (element[3] === 3 && tag.toString() === element[1]) {
                const newData = { icon: {}, type: element[3] };
                exerciseTools2.map((body: ExCommonCodeType1) => {
                  if (element[4] === body.text) {
                    newData.icon = body.icon;
                  }
                });
                newArr.push(newData);
              }
            }
          }
        }
      }
      setToolBody(newArr);
    }
  }, [commonCode, data]);
  return (
    <div className={`exerciseTypeRectangle`}>
      {isToolBody && isToolBody?.length > 0 ? (
        <>
          {data?.category === 2 ||
          (data?.category === 1 && data?.libraryGroup === 9) ? (
            <>
              {isToolBody?.length > 0 ? (
                isToolBody?.map((ToolBody: any, index: number) => (
                  <React.Fragment key={index}>
                    {index < tagHideCount && (
                      <p
                        className={
                          ToolBody.type === 2
                            ? `bodyIcon typeIcon`
                            : `toolIcon typeIcon`
                        }
                        key={`${data?._id}_toolIcon_${index}`}
                      >
                        {data?.category === 2 ||
                        (data?.category === 1 && data?.libraryGroup) ? (
                          <span className={`tagText`}>{t(ToolBody)}</span>
                        ) : (
                          <>
                            {ToolBody.type === 3 ? (
                              <span className={`icon`}>{ToolBody.icon}</span>
                            ) : (
                              <span className={`icon`}>
                                <img src={ToolBody.icon} alt="icon" />
                              </span>
                            )}
                          </>
                        )}
                      </p>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <span className={`icon`}></span>
              )}
              {isToolBody?.length > tagHideCount && (
                <p className={`numIcon typeIcon`}>
                  <span className={`icon caption3_regular`}>
                    +{isToolBody?.length - tagHideCount}
                  </span>
                </p>
              )}
            </>
          ) : (
            <>
              {isToolBody?.length > 0 ? (
                isToolBody?.map((ToolBody: any, index: number) => (
                  <React.Fragment key={index}>
                    {index < 5 && (
                      <p
                        className={
                          ToolBody.type === 2
                            ? `bodyIcon typeIcon`
                            : `toolIcon typeIcon`
                        }
                        key={`${data?._id}_toolIcon_${index}`}
                      >
                        {data?.category === 2 ? (
                          <span className={`tagText`}>{t(ToolBody)}</span>
                        ) : (
                          <>
                            {ToolBody.type === 3 ? (
                              <span className={`icon`}>{ToolBody.icon}</span>
                            ) : (
                              <span className={`icon`}>
                                <img src={ToolBody.icon} alt="icon" />
                              </span>
                            )}
                          </>
                        )}
                      </p>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <span className={`icon`}></span>
              )}
              {isToolBody?.length > 5 && (
                <p className={`numIcon typeIcon`}>
                  <span className={`icon caption3_regular`}>
                    +{isToolBody?.length - 5}
                  </span>
                </p>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <p className={`no_data typeIcon`}>
            <span className={`no_data`}></span>
          </p>
          <p className={`no_data  typeIcon`}>
            <span className={`no_data`}></span>
          </p>
          <p className={`no_data typeIcon`}>
            <span className={`no_data`}></span>
          </p>
        </>
      )}
    </div>
  );
};

export default ExerciseTypeRectangle;
