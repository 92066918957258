import api from "../utils/api";
import { URL } from "../datas/common";
import { useQuery } from "react-query";

//운동 리스트
const exerciseNav = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/exercise/exercisemain_kiosk`, {
    ...param,
  });
  return data;
};

export const useExerciseNav = (param: any) => {
  return useQuery(["exercisemain", param], () => exerciseNav(param), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

//운동 상세보기
const exerciseDetail = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/exercise/detail`, {
    ...param,
  });
  return data;
};

export const useExerciseDetail = (param: any) => {
  return useQuery(["exerciseDetail", param], () => exerciseDetail(param), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

//운동 전체보기
const getexercise = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/exercise/getexercise_kiosk`, {
    ...param,
  });
  return data;
};

export const useGetexercise = (param: any) => {
  return useQuery(["getexercise", param], () => getexercise(param), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

//보건소 운동 프로그램 이탈자 등록
const failure = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/exercise/failure`, {
    ...param,
  });
  return data;
};

export const useFailure = (param: any) => {
  return useQuery(["failure", param], () => failure(param), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

//Exercise 운동 결과 저장
const saveresult = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/exercise/saveresult`, {
    ...param,
  });
  return data;
};

export const useSaveresult = (param: any) => {
  return useQuery(["saveresult", param], () => saveresult(param), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};

//케마 영상 전체 리스트 조회
const healthmovies = async (param: any): Promise<any> => {
  const { data } = await api.post(`${URL}/health/healthmovies`, {
    ...param,
  });
  return data;
};

export const useHealthmovies = (param: any) => {
  return useQuery(["healthmovies", param], () => healthmovies(param), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};
