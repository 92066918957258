import "./SquareTextBox.scss";

interface Props {
  text: string;
}

const SquareTextBox = ({ text }: Props) => {
  return (
    <>
      {text ? (
        <span className={`caption3_medium squareTextBox `}>{text}</span>
      ) : (
        <span className={`caption3_medium squareTextBoxNoTitle no_data`}>
          {text}
        </span>
      )}
    </>
  );
};

export default SquareTextBox;
